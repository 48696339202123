import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import numeral from "numeral"
import Box from "@mui/material/Box"
import Collapse from "@mui/material/Collapse"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import TextField from "@mui/material/TextField"
import IconButton from "@mui/material/IconButton"
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined"
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"

import { NOT_SPECIFIED } from "~/util"
import PositiveIntegerInput from "../PositiveIntegerInput"
import SelectorField from "../SelectorField"
import ChecklistLineNotes from "./ChecklistLineNotes"
import {
  PileTypeOptions,
  HelixTypeOptions,
  calculateRequiredCompressionCapacity,
  calculateRequiredTorque,
  setAchievedCompAndTension,
} from "./util"
import { ChecklistLineItem, User } from "~/types"
import cornerMarker from "~/images/corner-tri-marker.png"
import DecimalInput from "../DecimalInput"

enum Field {
  PILE_USED = "PILE_USED",
  HELIX_USED = "HELIX_USED",
  REQUIRED_COMPRESSION_CAPACITY = "REQUIRED_COMPRESSION_CAPACITY",
  REQUIRED_TORQUE = "REQUIRED_TORQUE",
  TOTAL_LENGTH = "TOTAL_LENGTH",
  CUT_OFF_ELEVATION = "CUT_OFF_ELEVATION",
  INCLINATION = "INCLINATION",
  TORQUE_ACHIEVED = "TORQUE_ACHIEVED",
}

function NotSpecified() {
  return <Box sx={{ textAlign: "center" }}>{NOT_SPECIFIED}</Box>
}

interface Props {
  readonly addNoteLoading?: boolean
  readonly deleteNoteLoadingIds?: string[]
  readonly editNoteLoadingIds?: string[]
  readonly onAddNote?: (pile: any, note: string) => void
  readonly onEditNote?: (id: string, text: string) => void
  readonly onDeleteNote?: (id: string) => void
  readonly onUpdatePile: (pile: any) => void
  readonly pile: ChecklistLineItem
  readonly onRemovePile: (pile: any) => void
  readonly templateMode: boolean
  readonly user: Partial<User>
}

function ChecklistTableRow({
  addNoteLoading,
  deleteNoteLoadingIds,
  editNoteLoadingIds,
  onAddNote,
  onEditNote,
  onDeleteNote,
  onUpdatePile,
  pile,
  onRemovePile,
  templateMode,
  user,
}: Props) {
  const { t } = useTranslation()
  const [openNotes, setOpenNotes] = useState(false)
  const [activeField, setActiveField] = useState<Field>()

  return (
    <>
      <TableRow sx={classes.row}>
        {!templateMode && (
          <TableCell style={{ position: "relative" }}>
            {pile.id && onAddNote ? (
              <>
                {(pile.notes ?? []).length > 0 && (
                  <Box sx={classes.rowNoteIndicator}>
                    <img src={cornerMarker} />
                  </Box>
                )}
                <IconButton
                  aria-label="expand row"
                  onClick={() => setOpenNotes(!openNotes)}
                  size="small"
                >
                  {openNotes ? <IndeterminateCheckBoxOutlinedIcon /> : <AddBoxOutlinedIcon />}
                </IconButton>
              </>
            ) : null}
          </TableCell>
        )}
        <TableCell align="center">
          <Box
            component="span"
            sx={{
              fontWeight: "bold",
              fontSize: "0.9rem",
              color: (theme) => theme.fielderColors.mutedText,
            }}
          >
            {pile.number}
          </Box>
        </TableCell>
        <TableCell>
          <SelectorField
            aria-label={t("page.checklist.pileUsed")}
            margin="dense"
            name={`pileUsed${pile.number}`}
            onChange={(pileType) => {
              const updatedPile = {
                ...pile,
                pileUsed: pileType.id,
              }

              if (updatedPile.requiredCompressionCapacity) {
                updatedPile.requiredTorque = calculateRequiredTorque(
                  pileType.id,
                  updatedPile.requiredCompressionCapacity
                )
              }

              if (updatedPile.achievedTorque) {
                setAchievedCompAndTension(updatedPile)
              }

              onUpdatePile(updatedPile)
            }}
            onFocus={() => {
              setActiveField(Field.PILE_USED)
            }}
            options={PileTypeOptions}
            size="small"
            sx={{ marginTop: "8px", marginBottom: "4px" }}
            value={pile.pileUsed}
            variant="outlined"
          />
        </TableCell>
        <TableCell>
          <SelectorField
            aria-label={t("page.checklist.helixUsed")}
            margin="dense"
            name={`helixUsed${pile.number}`}
            onChange={(s) => {
              const updatedPile = {
                ...pile,
                helixUsed: s.id,
              }
              onUpdatePile(updatedPile)
            }}
            onFocus={() => {
              setActiveField(Field.HELIX_USED)
            }}
            options={HelixTypeOptions}
            size="small"
            sx={{ marginTop: "8px", marginBottom: "4px" }}
            value={pile.helixUsed}
            variant="outlined"
          />
        </TableCell>
        <TableCell>
          <PositiveIntegerInput
            customInput={TextField}
            margin="dense"
            max={999999}
            name={`requiredCompressionCapacity${pile.number}`}
            onChange={(e) => {
              if (activeField === Field.REQUIRED_COMPRESSION_CAPACITY) {
                const value = numeral(parseInt(e.target.value || "0", 10)).value()
                const requiredTorque = numeral(
                  calculateRequiredTorque(pile.pileUsed, value)
                ).value()
                const updatedPile = {
                  ...pile,
                  requiredCompressionCapacity: value,
                  requiredTorque: requiredTorque,
                }
                onUpdatePile(updatedPile)
              }
            }}
            onFocus={(e) => {
              setActiveField(Field.REQUIRED_COMPRESSION_CAPACITY)
              setTimeout(() => {
                // This is a hack to work around a bug (or poor design) in React-Number-Format
                e.target.select()
              }, 8)
            }}
            placeholder="0"
            size="small"
            value={pile.requiredCompressionCapacity ?? ""}
            variant="outlined"
          />
        </TableCell>
        <TableCell align="center">
          <PositiveIntegerInput
            customInput={TextField}
            margin="dense"
            max={999999}
            name={`requiredTorque${pile.number}`}
            onChange={(e) => {
              if (activeField === Field.REQUIRED_TORQUE) {
                const value = numeral(parseInt(e.target.value || "0", 10)).value()
                const requiredCompressionCapacity = numeral(
                  calculateRequiredCompressionCapacity(pile.pileUsed, value)
                ).value()
                const updatedPile = {
                  ...pile,
                  requiredCompressionCapacity: requiredCompressionCapacity,
                  requiredTorque: value,
                }
                onUpdatePile(updatedPile)
              }
            }}
            onFocus={(e) => {
              setActiveField(Field.REQUIRED_TORQUE)
              setTimeout(() => {
                // This is a hack to work around a bug (or poor design) in React-Number-Format
                e.target.select()
              }, 8)
            }}
            placeholder="0"
            size="small"
            value={pile.requiredTorque ?? ""}
            variant="outlined"
          />
        </TableCell>
        <TableCell>
          {!templateMode ? (
            <DecimalInput
              customInput={TextField}
              margin="dense"
              max={999999}
              onChange={(e) => {
                const updatedPile = {
                  ...pile,
                  totalPileLength: numeral(e.target.value).value(),
                }
                if (updatedPile.achievedTorque) {
                  setAchievedCompAndTension(updatedPile)
                }
                onUpdatePile(updatedPile)
              }}
              placeholder="0"
              scale={3}
              size="small"
              style={{ backgroundColor: "#ffffff" }}
              value={pile.totalPileLength ?? ""}
              variant="outlined"
            />
          ) : (
            <NotSpecified />
          )}
        </TableCell>
        <TableCell>
          {!templateMode ? (
            <DecimalInput
              customInput={TextField}
              margin="dense"
              max={999999}
              name={`cutOffElevation${pile.number}`}
              onChange={(e) => {
                const updatedPile = {
                  ...pile,
                  cutOffElevation: numeral(e.target.value).value(),
                }
                onUpdatePile(updatedPile)
              }}
              placeholder="0"
              scale={3}
              size="small"
              value={pile.cutOffElevation ?? ""}
              variant="outlined"
            />
          ) : (
            <NotSpecified />
          )}
        </TableCell>
        <TableCell>
          {!templateMode ? (
            <DecimalInput
              customInput={TextField}
              margin="dense"
              max={999999}
              name={`pileInclination${pile.number}`}
              onChange={(e) => {
                const updatedPile = {
                  ...pile,
                  pileInclination: numeral(e.target.value).value(),
                }
                onUpdatePile(updatedPile)
              }}
              placeholder="0"
              scale={3}
              size="small"
              value={pile.pileInclination ?? ""}
              variant="outlined"
            />
          ) : (
            <NotSpecified />
          )}
        </TableCell>
        <TableCell>
          {!templateMode ? (
            <PositiveIntegerInput
              customInput={TextField}
              disabled={templateMode}
              margin="dense"
              max={999999}
              name={`achievedTorque${pile.number}`}
              onChange={(e) => {
                const updatedPile = {
                  ...pile,
                  achievedTorque: numeral(e.target.value).value(),
                }
                setAchievedCompAndTension(updatedPile)
                onUpdatePile(updatedPile)
              }}
              placeholder="0"
              size="small"
              value={pile.achievedTorque ?? ""}
              variant="outlined"
            />
          ) : (
            <NotSpecified />
          )}
        </TableCell>
        <TableCell align="center">
          {pile.achievedCompressionCapacity
            ? numeral(pile.achievedCompressionCapacity).format()
            : NOT_SPECIFIED}
        </TableCell>
        <TableCell align="center">
          {pile.achievedTensionCapacity
            ? numeral(pile.achievedTensionCapacity).format()
            : NOT_SPECIFIED}
        </TableCell>
        <TableCell align="right">
          <IconButton
            aria-label={t("delete") as string}
            onClick={() => onRemovePile?.(pile.diagramId)}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      {!templateMode && onAddNote ? (
        <TableRow style={{ border: "none" }}>
          <TableCell colSpan={13} style={{ padding: 0, border: "none" }}>
            <Collapse in={openNotes} timeout="auto" unmountOnExit>
              <ChecklistLineNotes
                addNoteLoading={addNoteLoading}
                deleteNoteLoadingIds={deleteNoteLoadingIds}
                editNoteLoadingIds={editNoteLoadingIds}
                notes={pile.notes}
                onAddNote={(note) => onAddNote(pile, note)}
                onDeleteNote={(noteId: string) => onDeleteNote?.(noteId)}
                onEditNote={(noteId: string, updatedText: string) => {
                  onEditNote?.(noteId, updatedText)
                }}
                user={user}
              />
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </>
  )
}

const classes = {
  row: {
    "& td": {
      padding: "0.25rem",
    },
  },
  rowNoteIndicator: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "0.75rem",
    height: "0.75rem",

    "& img": {
      width: "100%",
      height: "100%",
      position: "absolute",
      top: 0,
      left: 0,
    },
  },
} as const

export default React.memo(ChecklistTableRow)
