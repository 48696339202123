/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { DraggingStyle, NotDraggingStyle } from "react-beautiful-dnd"
import Collapse from "@mui/material/Collapse"
import TextField from "@mui/material/TextField"
import IconButton from "@mui/material/IconButton"
import Box from "@mui/material/Box"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import VisibilityIcon from "@mui/icons-material/VisibilityOutlined"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOffOutlined"
import DragHandleIcon from "@mui/icons-material/DragHandle"
import Button from "@mui/material/Button"

import MoneyInput from "./MoneyInput"
import PositiveIntegerInput from "./PositiveIntegerInput"
import OrganizationItemSelect from "./OrganizationItemSelect"
import TaxRateGroupSelect from "./TaxRateGroupSelect"
import LineItemDetail from "./LineItemDetail"
import { asInt, formatMoney, isEmpty } from "~/util"
import { ItemType, TaxRateGroup, TransactionLineItemFormInput } from "~/types"

function getDefaultQuantity(variant: TransactionType, value: number | string): string {
  if (variant === "WORK_ORDER") {
    return `${value}`
  } else {
    const intValue = asInt(value, true)
    return `${intValue}`
  }
}

export type BeautifulDndRowStyle = DraggingStyle | NotDraggingStyle | undefined
export type RowStyle = BeautifulDndRowStyle & {
  userSelect: string
  background: string
}

type TransactionType = "ESTIMATE" | "ESTIMATE_TEMPLATE" | "WORK_ORDER" | "INVOICE"

interface LineItemProps {
  readonly canEdit: boolean
  readonly allowBundleModifications: boolean
  readonly currencyCode: string
  readonly lineItem: TransactionLineItemFormInput
  readonly onChange: (reason: string, payload: any) => void
  readonly onDelete: (lineItem: TransactionLineItemFormInput) => void
  readonly taxRateGroupOptions: TaxRateGroup[]
  readonly taxRateGroupsOptionsLoading?: boolean
  readonly variant?: TransactionType
  readonly style?: RowStyle
}

const LineItem = React.forwardRef<HTMLDivElement, LineItemProps>(function LineItem(props, ref) {
  const {
    canEdit = true,
    allowBundleModifications,
    currencyCode,
    lineItem,
    onChange,
    onDelete,
    taxRateGroupOptions,
    taxRateGroupsOptionsLoading,
    variant,
    ...other
  } = props
  const { t } = useTranslation()
  const [description, setDescription] = useState<string>(lineItem.description ?? "")
  const [quantity, setQuantity] = useState<string>(() => {
    return !isEmpty(lineItem.quantity) ? `${lineItem.quantity}` : ""
  })
  const [unitPrice, setUnitPrice] = useState<string>(() =>
    !isEmpty(lineItem.unitPrice) ? `${lineItem.unitPrice}` : ""
  )

  useEffect(() => {
    setQuantity(!isEmpty(lineItem.quantity) ? `${lineItem.quantity}` : "")
    setUnitPrice(!isEmpty(lineItem.unitPrice) ? `${lineItem.unitPrice}` : "")
  }, [lineItem])

  return (
    <div {...other} ref={ref}>
      <div css={classes.iconCell} title={t("component.lineItem.dragRowTooltip") as string}>
        <DragHandleIcon color="action" />
      </div>
      <div>
        <OrganizationItemSelect
          error={lineItem.errors?.organizationItemId}
          onChange={(value, reason) => {
            if (reason === "selectOption") {
              setUnitPrice(value ? `${value.unitSalePrice}` : "")
              setDescription(value?.description ?? value?.name ?? "")
              onChange("CHANGE_ITEM", {
                lineItem,
                selectedItem: value,
              })
            } else if (reason === "clear") {
              setUnitPrice("")
              setDescription("")
              onChange("CLEAR_ITEM", {
                lineItem,
                selectedItem: null,
              })
            }
          }}
          style={{
            margin: 0,
            fontSize: "0.9rem",
            minWidth: "250px",
          }}
          value={lineItem.organizationItem}
        />
      </div>
      <div>
        <TextField
          fullWidth
          inputProps={{ maxLength: 255 }}
          onBlur={() => {
            onChange("CHANGE_DESCRIPTION", {
              lineItem,
              description,
            })
          }}
          onChange={(e) => setDescription(e.target.value)}
          onFocus={(e) => e.target.select()}
          placeholder={t("description") as string}
          size="small"
          value={description}
          variant="outlined"
        />
      </div>
      <div>
        <PositiveIntegerInput
          aria-label={t("quantity") as string}
          customInput={TextField}
          error={
            lineItem.errors?.showErrors || lineItem.touched?.quantity
              ? !!lineItem.errors?.quantity
              : undefined
          }
          fullWidth={false}
          inputProps={{ min: 0, maxLength: 12, style: { textAlign: "right" } }}
          name="quantity"
          onBlur={(e) => {
            onChange("CHANGE_QUANTITY", {
              lineItem,
              quantity: getDefaultQuantity(variant, e.target.value),
            })
          }}
          onChange={(e) => {
            setQuantity(String(getDefaultQuantity(variant, e.target.value) ?? ""))
          }}
          placeholder={t("quantity") as string}
          required
          size="small"
          sx={classes.noMargin}
          value={quantity}
          variant="outlined"
        />
      </div>
      {variant !== "WORK_ORDER" && (
        <>
          {lineItem.organizationItem?.type === ItemType.BUNDLE ||
          variant === "ESTIMATE_TEMPLATE" ? (
            <div css={classes.calculatedValue}>{formatMoney(currencyCode, unitPrice)}</div>
          ) : (
            <div>
              <MoneyInput
                aria-label={t("unitPrice")}
                currencyCode={currencyCode}
                customInput={TextField}
                error={
                  lineItem.touched?.unitPrice || lineItem.errors?.showErrors
                    ? !!lineItem.errors?.unitPrice
                    : null
                }
                fullWidth={false}
                max={999999999999}
                min={0}
                onBlur={() => {
                  onChange("CHANGE_UNIT_PRICE", {
                    lineItem,
                    unitPrice,
                  })
                }}
                onChange={(e) => setUnitPrice(e.target.value)}
                placeholder="0"
                required
                size="small"
                sx={[classes.noMargin, classes.unitPriceTextField]}
                value={unitPrice}
                variant="outlined"
              />
            </div>
          )}
          <div css={classes.calculatedValue}>{formatMoney(currencyCode, lineItem.subTotal)}</div>
          <div>
            <TaxRateGroupSelect
              elementId={`tax-rate-group-selector-${lineItem.number}`}
              error={
                (lineItem.touched?.taxRateGroup || lineItem.errors?.showErrors) &&
                !!lineItem.errors?.taxRateGroup
                  ? lineItem.errors?.taxRateGroup
                  : ""
              }
              hideLabel
              loading={taxRateGroupsOptionsLoading}
              onChange={(selection) => {
                onChange("CHANGE_TAX_RATE", {
                  lineItem,
                  taxRateGroup: selection,
                })
              }}
              options={taxRateGroupOptions}
              size="small"
              taxRateGroupId={lineItem.taxRateGroup?.id}
              value={lineItem.taxRateGroup}
            />
          </div>
        </>
      )}
      {canEdit ? (
        <div css={classes.iconCell}>
          <IconButton
            aria-label="delete"
            onClick={() => onDelete(lineItem)}
            sx={classes.deleteButton}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ) : null}
      {lineItem.lineItemDetails ? (
        <div
          style={{
            gridColumn: "1 / -1",
            marginTop: "0.15rem",
          }}
        >
          <Collapse in={lineItem.showDetails} timeout="auto" unmountOnExit>
            {lineItem.lineItemDetails
              .toSorted((a, b) => a.number - b.number)
              .map((d) => (
                <LineItemDetail
                  allowBundleModifications={allowBundleModifications}
                  currencyCode={currencyCode}
                  key={d.id ?? d.organizationItemId}
                  lineItemDetail={d}
                  onChangePrice={(val) => {
                    onChange("CHANGE_COMPONENT_PRICE", {
                      componentItemId: d.organizationItem?.id,
                      lineItem,
                      unitPrice: val,
                    })
                  }}
                  onChangeQuantity={(val) => {
                    if (allowBundleModifications) {
                      onChange("CHANGE_COMPONENT_QUANTITY", {
                        componentItemId: d.organizationItem?.id,
                        lineItem,
                        quantity: val,
                      })
                    }
                  }}
                  onDelete={() => {
                    if (allowBundleModifications) {
                      onChange("DELETE_COMPONENT", {
                        componentItemId: d.organizationItem?.id,
                        lineItem,
                      })
                    }
                  }}
                  variant={variant}
                />
              ))}
          </Collapse>
        </div>
      ) : null}
      {lineItem.lineItemDetails && lineItem.lineItemDetails?.length > 0 ? (
        <Box
          sx={{
            gridColumn: "2 / span 2",
            display: "flex",
            flexDirection: "row",
            gap: "0.5rem",
            alignItems: "center",
            marginLeft: "0.25rem",
          }}
        >
          <Button
            onClick={(e) => {
              onChange("CHANGE_SHOW_DETAILS", {
                lineItem,
                showDetails: !lineItem.showDetails,
              })
            }}
            startIcon={
              lineItem.showDetails ? (
                <VisibilityOffIcon fontSize="small" />
              ) : (
                <VisibilityIcon fontSize="small" />
              )
            }
          >
            <Box component="span">
              {lineItem.showDetails
                ? t(`component.lineItem.hideComponents`)
                : (t(`component.lineItem.showComponents`) as string)}
            </Box>
          </Button>
        </Box>
      ) : null}
    </div>
  )
})

const classes = {
  iconCell: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  calculatedValue: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "0.95rem",
    fontWeight: 600,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingRight: "0.5rem",
  },
  unitPriceTextField: {
    minWidth: "100px",
  },
  noMargin: {
    margin: 0,
    width: "100%",
  },
  deleteButton: {
    padding: "0.5rem",
  },
} as const

export default LineItem
