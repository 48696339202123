/* eslint-disable react/iframe-missing-sandbox */
import React, { useState } from "react"
import * as Sentry from "@sentry/react"
import { Trans, useTranslation } from "react-i18next"
import AttachFileIcon from "@mui/icons-material/AttachFile"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import Chip from "@mui/material/Chip"
import Popover from "@mui/material/Popover"
import Paper, { PaperProps } from "@mui/material/Paper"
import CloseIcon from "@mui/icons-material/Close"
import Draggable from "react-draggable"
import { useQuery, gql } from "@apollo/client"

import { getFilename, parseGraphQLErrorCode } from "~/util"
import { Attachment, AttachmentType } from "~/types"
import { FileTypeIcon } from "./icons/FileTypeIcon"
import IconButton from "@mui/material/IconButton"

const GET_ESTIMATE_FILE = gql`
  query GetEstimateFile($id: ID!) {
    getEstimateById(id: $id) {
      id
      number
      pdfFile
    }
  }
`

const GET_INVOICE_FILE = gql`
  query GetInvoiceFile($id: ID!) {
    getInvoiceById(id: $id) {
      id
      number
      pdfFile
    }
  }
`

const GET_CHECKLIST_FILE = gql`
  query GetChecklistFile($id: ID!) {
    getChecklistById(id: $id) {
      id
      pdfFile
    }
  }
`

interface Props {
  readonly attachmentIds: string[]
  readonly availableFiles?: Attachment[]
  readonly isUploading?: boolean
  readonly onClickUploadFromComputer: () => void
  readonly onDeselectAttachment: (attachmentId: string) => void
  readonly onSelectAttachment: (attachmentId: string) => void
}

function EmailAttachments({
  attachmentIds,
  availableFiles,
  isUploading,
  onClickUploadFromComputer,
  onDeselectAttachment,
  onSelectAttachment,
}: Props) {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [selectedAttachmentForPreview, setSelectedAttachmentForPreview] = useState<
    Attachment | null | undefined
  >(null)
  const [loadEstimateFileId, setLoadEstimateFileId] = useState<string>()
  const [loadInvoiceFileId, setLoadInvoiceFileId] = useState<string>()
  const [loadChecklistFileId, setLoadChecklistFileId] = useState<string>()

  const { loading: estimateFileLoading } = useQuery(GET_ESTIMATE_FILE, {
    variables: {
      id: loadEstimateFileId,
    },
    skip: !loadEstimateFileId,
    fetchPolicy: "cache-first",
    onCompleted: (data) => {
      setLoadEstimateFileId(undefined)
      generateSignedUrlFromPdfString(data?.getEstimateById?.pdfFile)
    },
    onError: (error) => {
      setLoadEstimateFileId(undefined)
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      console.log(`Error loading estimate file: ${errorCode}`)
    },
  })

  const { loading: invoiceFileLoading } = useQuery(GET_INVOICE_FILE, {
    variables: {
      id: loadInvoiceFileId,
    },
    skip: !loadInvoiceFileId,
    fetchPolicy: "cache-first",
    onCompleted: (data) => {
      setLoadInvoiceFileId(undefined)
      generateSignedUrlFromPdfString(data?.getInvoiceById?.pdfFile)
    },
    onError: (error) => {
      setLoadInvoiceFileId(undefined)
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      console.log(`Error loading invoice file: ${errorCode}`)
    },
  })

  const { loading: checklistFileLoading } = useQuery(GET_CHECKLIST_FILE, {
    variables: {
      id: loadChecklistFileId,
    },
    skip: !loadChecklistFileId,
    fetchPolicy: "cache-first",
    onCompleted: (data) => {
      setLoadChecklistFileId(undefined)
      generateSignedUrlFromPdfString(data?.getChecklistById?.pdfFile)
    },
    onError: (error) => {
      setLoadChecklistFileId(undefined)
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      console.log(`Error loading checklist file: ${errorCode}`)
    },
  })

  function generateSignedUrlFromPdfString(pdfString: string) {
    if (!pdfString) {
      setSelectedAttachmentForPreview(null)
      return
    }

    const byteCharacters = atob(pdfString)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const file = new Blob([byteArray], { type: "application/pdf;base64" })
    // eslint-disable-next-line compat/compat
    const fileURL = URL.createObjectURL(file)
    setSelectedAttachmentForPreview({
      ...selectedAttachmentForPreview,
      signedUrl: fileURL,
    })
  }

  const handleClickAttachmentsIcon = (event: any) => {
    if (availableFiles?.length) {
      setAnchorEl(event.currentTarget)
    } else {
      onClickUploadFromComputer()
    }
  }

  const handleCloseAttachmentsPopover = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        border: "1px solid rgba(0, 0, 0, 0.23)",
        borderRadius: "4px",
        padding: "0.25rem",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box>
        {attachmentIds?.map((id) => (
          <Chip
            key={id}
            label={getFilename(availableFiles?.find((f) => f.id === id), t)}
            onClick={() => {
              const file = availableFiles?.find((f) => f.id === id)
              if (file) {
                if (file.type === AttachmentType.ESTIMATE) {
                  setLoadEstimateFileId(file.id)
                } else if (file.type === AttachmentType.INVOICE) {
                  setLoadInvoiceFileId(file.id)
                } else if (file.type === AttachmentType.CHECKLIST) {
                  setLoadChecklistFileId(file.id)
                }
                setSelectedAttachmentForPreview(file)
              }
            }}
            onDelete={() => {
              onDeselectAttachment?.(id)
            }}
            sx={classes.selectedAttachmentChip}
          />
        ))}
      </Box>
      <Button
        color="secondary"
        disabled={isUploading}
        onClick={handleClickAttachmentsIcon}
        sx={{
          height: "100%",
          margin: "0.25rem",
          border: "none",
          padding: "0.5rem",
          "&:hover": {
            border: "none",
          },
        }}
        title={t("component.emailDialog.addFilesButton.tooltip") as string}
        variant="outlined"
      >
        {isUploading ? (
          <CircularProgress color="secondary" size={20} thickness={6.0} />
        ) : (
          <AttachFileIcon />
        )}
      </Button>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        onClose={handleCloseAttachmentsPopover}
        open={open}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Box sx={classes.selectorContainer}>
          <Box sx={classes.selectorTitle}>{t("component.emailAttachments.title")}</Box>
          <Box sx={classes.selectorFilesContainer}>
            {availableFiles?.map((file: Attachment) => {
              const isSelected = attachmentIds?.includes(file.id)
              return (
                <Box
                  key={file.id}
                  onClick={(event) => {
                    event.preventDefault()
                    if (isSelected) {
                      onDeselectAttachment?.(file.id)
                    } else {
                      onSelectAttachment?.(file.id)
                    }
                  }}
                  sx={classes.selectorFileRow}
                >
                  <Box sx={classes.selectorFileRowPreview}>
                    {file.contentType?.startsWith("image") ? (
                      <Box
                        component="img"
                        src={file.signedUrl}
                        sx={classes.selectorPreviewImage}
                        title={file.name}
                      />
                    ) : (
                      <Box sx={classes.selectorPreviewImage}>
                        <FileTypeIcon
                          fileName={file.name}
                          style={{
                            color: "black",
                            width: "100%",
                            height: "100%",
                            padding: "0.5rem",
                            borderRadius: "4px",
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                  <Box sx={classes.selectorFileRowMeta}>
                    <Box sx={classes.selectorFilename}>{getFilename(file, t)}</Box>
                    {isSelected ? (
                      <Box sx={classes.selectedAttachmentCheck}>
                        <CheckCircleIcon sx={classes.selectedAttachmentCheckIcon} />
                      </Box>
                    ) : null}
                  </Box>
                </Box>
              )
            })}
          </Box>
          <Box sx={classes.selectorAltActionContainer}>
            <Box sx={classes.selectorAltActionLabel}>
              {t("component.emailAttachments.altActionTitle")}
            </Box>
            <Button
              color="secondary"
              disabled={isUploading}
              onClick={onClickUploadFromComputer}
              sx={classes.selectorUploadButton}
              variant="outlined"
            >
              {isUploading ? (
                <CircularProgress color="secondary" size={20} thickness={6.0} />
              ) : (
                t("component.emailAttachments.uploadFromComputerButtonTitle")
              )}
            </Button>
          </Box>
        </Box>
      </Popover>
      <Dialog
        PaperComponent={PaperComponent}
        PaperProps={{
          sx: {
            margin: "1rem",
            position: "relative",
            height: "95vh", // Set dialog height to 95% of the viewport
          },
        }}
        disableEscapeKeyDown={false}
        fullWidth={!selectedAttachmentForPreview?.contentType?.startsWith("image")}
        maxWidth="lg"
        onClose={() => setSelectedAttachmentForPreview(null)}
        open={Boolean(selectedAttachmentForPreview)}
      >
        <DialogTitle
          className="attachment-preview-drag-handle"
          sx={{
            p: "0.625rem",
            pl: "1rem",
            cursor: "move",
            backgroundColor: "#212121",
            color: "#fff",
            display: "flex",
            justifyContent: "space-between", // Align title and close button
            alignItems: "center",
          }}
        >
          {selectedAttachmentForPreview?.name}
          {/* Close button */}
          <IconButton
            aria-label="close"
            onClick={() => setSelectedAttachmentForPreview(null)}
            sx={{
              color: "#fff",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            padding: "0",
            margin: "0",
            height: "100%",
            overflow: "hidden", // Prevent the iframe from extending beyond dialog
          }}
        >
          <Box
            sx={{
              height: "100%",
              width: "100%",
              padding: "0",
              margin: "0rem",
              display: "flex",
              flexDirection: "column", // Ensure content flows vertically
              justifyContent: "center",
              backgroundColor: "#e2e8f0",
            }}
          >
            {estimateFileLoading || invoiceFileLoading || checklistFileLoading ? (
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="secondary" size={40} thickness={6.0} />
                <p>
                  {t("loading")}
                  ...
                </p>
              </Box>
            ) : selectedAttachmentForPreview?.contentType?.startsWith("image") ? (
              <img
                src={selectedAttachmentForPreview?.signedUrl}
                style={{
                  objectFit: "contain",
                  maxHeight: "100%",
                  maxWidth: "100%",
                  margin: "auto", // Center the image
                }}
              />
            ) : (
              <Box
                sx={{
                  flex: 1,
                  overflow: "auto", // Enable scrolling for PDF content
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {selectedAttachmentForPreview &&
                !isAttachmentPreviewable(selectedAttachmentForPreview) ? (
                  <Box
                    sx={{
                      alignSelf: "center",
                    }}
                  >
                    <Trans
                      components={{
                        anchor: <a href={selectedAttachmentForPreview?.signedUrl} />,
                      }}
                      i18nKey="component.emailAttachments.fileTypeNotPreviewable"
                    />
                  </Box>
                ) : (
                  <iframe
                    src={selectedAttachmentForPreview?.signedUrl}
                    style={{
                      border: "none",
                      height: "100%",
                      width: "100%",
                    }}
                  />
                )}
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  )
}

function isAttachmentPreviewable(attachment: Attachment): boolean {
  return Boolean(
    attachment.contentType &&
      (attachment.contentType.startsWith("image") ||
        attachment.contentType.startsWith("application/pdf") ||
        attachment.contentType.startsWith("text/plain") ||
        attachment.contentType.startsWith("video"))
  )
}

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      bounds={{ top: -100 }}
      cancel={'[class*="MuiDialogContent-root"]'}
      handle=".attachment-preview-drag-handle"
    >
      <Paper {...props} />
    </Draggable>
  )
}

const classes = {
  selectedAttachmentChip: {
    margin: "0.15rem 0.25rem",
  },
  selectedAttachmentCheck: {
    width: "1rem",
    height: "1rem",
  },
  selectedAttachmentCheckIcon: {
    color: "green",
    width: "100%",
    height: "100%",
  },
  selectorContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    width: "345px",
    minWidth: "20rem",
    backgroundColor: "#f9f9f9",
  },
  selectorTitle: {
    padding: "0 1rem 0.5rem 1rem",
    borderBottom: "1px solid #dcdcdc",
    fontWeight: "600",
  },
  selectorFilesContainer: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "18.75rem",
    overflowY: "scroll",
    backgroundColor: "#fff",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
  },
  selectorFileRow: {
    padding: "0.4rem 1rem 0.4rem 1rem",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#FFFCDF",
    },
  },
  selectorFileRowPreview: {
    lineHeight: 0,
  },
  selectorPreviewImage: {
    width: "3.125rem",
    height: "3.125rem",
    objectFit: "cover",
    borderRadius: "4px",
  },
  selectorFileRowMeta: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  selectorFilename: {
    marginLeft: "0.875rem",
    marginRight: "1.5rem",
    paddingRight: "1.5rem",
    fontWeight: "600",
    width: "13rem",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  selectorAltActionContainer: {
    paddingTop: "0.5rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    alignContent: "center",
    borderTop: "1px solid #dcdcdc",
    fontWeight: "600",
  },
  selectorAltActionLabel: {
    marginBottom: "0.5rem",
  },
  selectorUploadButton: {
    width: "100%",
  },
} as const

export default EmailAttachments
