import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import OpenIcon from "@mui/icons-material/DraftsOutlined"
import ClosedIcon from "@mui/icons-material/MarkEmailReadOutlined"

import FilterMenu from "~/components/FilterMenu"
import MenuItem from "@mui/material/MenuItem"

interface Props {
  readonly onChangeStatusFilter: (status: "open" | "closed") => void
  readonly onChangeSort: (sort: "newest" | "oldest") => void
  readonly selectedStatus: "open" | "closed"
  readonly selectedSort: "newest" | "oldest"
}

export default function ConversationListMenuBar({
  onChangeStatusFilter,
  onChangeSort,
  selectedStatus,
  selectedSort,
}: Props) {
  const { t } = useTranslation()

  const [statusFilterMenuAnchor, setStatusFilterMenuAnchor] = useState<
    EventTarget & HTMLButtonElement
  >()
  const showStatusFiltersMenu = Boolean(statusFilterMenuAnchor)
  const [sortMenuAnchor, setSortMenuAnchor] = useState<EventTarget & HTMLButtonElement>()
  const showSortMenu = Boolean(sortMenuAnchor)

  const statusOptions = useMemo(
    () => [
      { value: "open", label: t("page.inbox.statusOption.OPEN") },
      { value: "closed", label: t("page.inbox.statusOption.CLOSED") },
    ],
    [t]
  )

  const sortByOptions = useMemo(
    () => [
      { value: "newest", label: t("page.inbox.sortByOption.NEWEST") },
      { value: "oldest", label: t("page.inbox.sortByOption.OLDEST") },
    ],
    [t]
  )

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
        backgroundColor: "white",
        padding: "0.25rem 1rem",
      }}
    >
      <FilterMenu
        anchorEl={statusFilterMenuAnchor}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        id="status-filter-menu"
        isOpen={showStatusFiltersMenu}
        label={statusOptions.find((o) => o.value === selectedStatus)?.label as string}
        labelStyle={{ fontWeight: "600" }}
        mutuallyExclusive
        onClickOption={(option) => {
          console.log(option)
          onChangeStatusFilter(option.value as "open" | "closed")
          setStatusFilterMenuAnchor(undefined)
        }}
        onClose={() => setStatusFilterMenuAnchor(undefined)}
        onOpen={(event) => setStatusFilterMenuAnchor(event.currentTarget)}
        options={statusOptions}
        renderOption={(option) => (
          <MenuItem
            key={option.value}
            onClick={() => {
              console.log(option)
              onChangeStatusFilter(option.value as "open" | "closed")
              setStatusFilterMenuAnchor(undefined)
            }}
            value={option.value}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              {option.value === "open" ? (
                <OpenIcon sx={{ fontSize: "1rem" }} />
              ) : (
                <ClosedIcon sx={{ fontSize: "1rem" }} />
              )}
              <Box>{option.label}</Box>
            </Box>
          </MenuItem>
        )}
        selectedOptions={[selectedStatus]}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      />
      <FilterMenu
        anchorEl={sortMenuAnchor}
        id="sort-menu"
        isOpen={showSortMenu}
        label={sortByOptions.find((o) => o.value === selectedSort)?.label as string}
        labelStyle={{ fontWeight: "600" }}
        menuSectionLabel={t("page.inbox.sortBy") as string}
        mutuallyExclusive
        onClickOption={(option) => {
          console.log(option)
          onChangeSort(option.value as "newest" | "oldest")
        }}
        onClose={() => setSortMenuAnchor(undefined)}
        onOpen={(event) => setSortMenuAnchor(event.currentTarget)}
        options={sortByOptions}
        selectedOptions={[selectedSort]}
      />
    </Box>
  )
}
