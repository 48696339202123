import React from "react"
import { useTranslation } from "react-i18next"
import Divider from "@mui/material/Divider"
import Box from "@mui/material/Box"
import Tooltip from "@mui/material/Tooltip"
import IconButton from "@mui/material/IconButton"
import TrashIcon from "@mui/icons-material/DeleteOutlined"
import ClosedIcon from "@mui/icons-material/MarkEmailReadOutlined"
import LabelIcon from "@mui/icons-material/SellOutlined"
import ConnectToJobIcon from "@mui/icons-material/WorkOutlineOutlined"

const TOOLTIP_DELAY = 500

interface Props {
  readonly onClickClose: () => void
  readonly onClickDelete: () => void
  readonly onClickLabel: () => void
  readonly onClickConnectWithJob: () => void
}

export default function HeaderConversationActions({
  onClickClose,
  onClickDelete,
  onClickLabel,
  onClickConnectWithJob,
}: Props) {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        backgroundColor: "white",
        gap: "0.5rem",
      }}
    >
      <Tooltip enterDelay={TOOLTIP_DELAY} title={t("page.inbox.delete")}>
        <IconButton sx={classes.button}>
          <TrashIcon onClick={onClickDelete} sx={classes.buttonIcon} />
        </IconButton>
      </Tooltip>
      <Divider flexItem orientation="vertical" variant="middle" />
      <Tooltip enterDelay={TOOLTIP_DELAY} title={t("page.inbox.close")}>
        <IconButton sx={classes.button}>
          <ClosedIcon onClick={onClickClose} sx={classes.buttonIcon} />
        </IconButton>
      </Tooltip>
      <Divider flexItem orientation="vertical" variant="middle" />
      <Tooltip enterDelay={TOOLTIP_DELAY} title={t("page.inbox.addLabel")}>
        <IconButton sx={classes.button}>
          <LabelIcon onClick={onClickLabel} sx={classes.buttonIcon} />
        </IconButton>
      </Tooltip>
      <Divider flexItem orientation="vertical" variant="middle" />
      <Tooltip enterDelay={TOOLTIP_DELAY} title={t("page.inbox.connectToJob")}>
        <IconButton sx={classes.button}>
          <ConnectToJobIcon onClick={onClickConnectWithJob} sx={classes.buttonIcon} />
        </IconButton>
      </Tooltip>
    </Box>
  )
}

const classes = {
  button: {
    width: "2.875rem",
  },
  buttonIcon: {
    color: "#4b5563",
  },
}
