import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import CircularProgress from "@mui/material/CircularProgress"
import InputAdornment from "@mui/material/InputAdornment"
import Button from "@mui/material/Button"

import { useAuth } from "~/context/AuthContext"

interface Props {
  readonly addNoteLoading: boolean
  readonly onAddNote: (note: string) => void
}

function NoteForm({ addNoteLoading, onAddNote }: Props) {
  const { t } = useTranslation()
  const { isImpersonating } = useAuth()
  const [note, setNote] = useState<string>("")

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "1rem",
      }}
    >
      <TextField
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {addNoteLoading ? <CircularProgress color="secondary" size={24} /> : null}
            </InputAdornment>
          ),
        }}
        disabled={addNoteLoading}
        fullWidth
        inputProps={{
          maxLength: 1000,
        }}
        maxRows="3"
        minRows="1"
        multiline
        name="note"
        onChange={(e: any) => setNote(e.target.value)}
        onKeyDown={(e: any) => {
          if (e.key === "Enter") {
            e.preventDefault()
            onAddNote?.(note)
            setNote("")
          }
        }}
        placeholder={
          addNoteLoading
            ? (`${t("saving")}...` as string)
            : (t("component.noteForm.placeholder") as string)
        }
        sx={{ background: "#FFFFFF" }}
        value={note}
        variant="outlined"
      />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Button
          disableElevation
          disabled={isImpersonating}
          onClick={() => {
            onAddNote?.(note)
            setNote("")
          }}
          sx={{ paddingLeft: "2rem", paddingRight: "2rem" }}
          variant="contained"
        >
          {t("save")}
        </Button>
      </Box>
    </Box>
  )
}

const classes = {
  buttonIcon: {
    fontSize: "1rem",
  },
} as const

export default NoteForm
