import React from "react"
import { useTranslation } from "react-i18next"
import Paper from "@mui/material/Paper"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import CircularProgress from "@mui/material/CircularProgress"

import HeaderBar from "./HeaderBar"
import ConversationList from "./ConversationList"
import { InboxConversation } from "~/types/apiTypes"
import TagChip from "./TagChip"
import ConversationMessage from "./ConversationMessage"
import inboxEmptyStateImage from "~/images/inbox-empty-state.png"

interface Props {
  readonly conversations: InboxConversation[]
  readonly isLoadingConversations: boolean
  readonly onChangeStatusFilter: (status: "open" | "closed") => void
  readonly onChangeSort: (sort: "newest" | "oldest") => void
  readonly onSelectConversation: (conversationId: string) => void
  readonly selectedConversationId?: string | null
  readonly selectedStatus: "open" | "closed"
  readonly selectedSort: "newest" | "oldest"
}

export default function InboxPanel({
  conversations,
  isLoadingConversations,
  selectedConversationId,
  onChangeStatusFilter,
  onChangeSort,
  onSelectConversation,
  selectedStatus,
  selectedSort,
}: Props) {
  const { t } = useTranslation()

  const selectedConversation = conversations.find(
    (conversation) => conversation.id === selectedConversationId
  )

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "calc(100vh - 6rem)",
        borderRadius: "4px",
        overflow: "hidden",
      }}
    >
      <Box sx={{ padding: "0.5rem" }}>
        <HeaderBar
          onClickClose={function (): void {
            throw new Error("Function not implemented.")
          }}
          onClickConnectWithJob={function (): void {
            throw new Error("Function not implemented.")
          }}
          onClickDelete={function (): void {
            throw new Error("Function not implemented.")
          }}
          onClickLabel={function (): void {
            throw new Error("Function not implemented.")
          }}
        />
      </Box>
      <Divider />
      <Box sx={{ display: "flex", flexDirection: "row", flex: 1, height: "calc(100vh - 13rem)" }}>
        {isLoadingConversations ? (
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <CircularProgress color="secondary" size={32} thickness={6.0} />
            <Box>{t("page.inbox.loadingConversations")}...</Box>
          </Box>
        ) : !conversations || conversations.length === 0 ? (
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Box sx={{ maxWidth: "26rem", textAlign: "center" }}>
              <img alt="empty inbox" height="auto" src={inboxEmptyStateImage} width="224px" />
              <Box sx={{ fontSize: "2.25rem" }}>{t("page.inbox.emptyState.title")}</Box>
              <Box sx={{ fontSize: "1.25rem", color: (theme) => theme.fielderColors.mutedText }}>
                {t("page.inbox.emptyState.description")}
              </Box>
            </Box>
          </Box>
        ) : (
          <>
            <ConversationList
              conversations={conversations}
              onChangeSort={onChangeSort}
              onChangeStatusFilter={onChangeStatusFilter}
              onSelectConversation={onSelectConversation}
              selectedConversationId={selectedConversationId}
              selectedSort={selectedSort}
              selectedStatus={selectedStatus}
            />
            <Box
              sx={{
                padding: "0.5rem",
                paddingBottom: "3rem",
                backgroundColor: "#fff",
                flex: 1,
                borderLeft: (theme) => `1px solid ${theme.fielderColors.lightGrey}`,
                height: "100%",
                overflowY: "scroll",
              }}
            >
              {selectedConversation ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "0.25rem",
                      padding: "0.25rem",
                      marginLeft: "2.5rem",
                    }}
                  >
                    {selectedConversation?.tags?.map((t) => <TagChip key={t.id} tag={t} />)}
                  </Box>
                  {selectedConversation?.messages?.map((message, index) => (
                    <>
                      <ConversationMessage
                        isLatestMessage={message.id === selectedConversation.latestMessage.id}
                        key={message.id}
                        message={message}
                      />
                      {index < selectedConversation.messages.length - 1 ? (
                        <Divider
                          sx={{ backgroundColor: "#f9fafb", margin: "1.5rem 0 1.5rem 2.5rem" }}
                        />
                      ) : null}
                    </>
                  ))}
                </>
              ) : null}
            </Box>
          </>
        )}
      </Box>
    </Paper>
  )
}
