/* eslint-disable react/no-danger */
import React from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import IncomingIcon from "@mui/icons-material/MoveToInboxOutlined"
import OutgoingIcon from "@mui/icons-material/OutboxOutlined"
import DOMPurify from "dompurify"

import { InboxMessage, MessageDirection } from "~/types/apiTypes"
import { useAuth } from "~/context/AuthContext"
import { formatDate } from "~/util"
import ReplyIcon from "~/components/icons/ReplyIcon"
import ReplyAllIcon from "~/components/icons/ReplyAllIcon"
import ForwardIcon from "~/components/icons/ForwardIcon"
import { Tooltip } from "@mui/material"

interface Props {
  readonly message: InboxMessage
  readonly isLatestMessage: boolean
}

export default function ConversationMessage({ message, isLatestMessage = false }: Props) {
  const { t } = useTranslation()
  const { user } = useAuth()

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "1rem",
        padding: "0.5rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          paddingTop: "0.5rem",
        }}
      >
        {message.direction === MessageDirection.CUSTOMER_REPLY ||
        message.direction === MessageDirection.INCOMING ? (
          <IncomingIcon />
        ) : (
          <OutgoingIcon />
        )}
      </Box>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            height: "2.5rem",
            minHeight: "40px",
          }}
        >
          <Box sx={{ fontWeight: "600", fontSize: "1.25rem" }}>
            {t(`page.inbox.messageDirectionAndChannel.${message.direction}_${message.channel}`)}
          </Box>
          {isLatestMessage ? (
            <Box>
              <Tooltip title={t("page.inbox.reply")}>
                <IconButton>
                  <ReplyIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("page.inbox.replyAll")}>
                <IconButton>
                  <ReplyAllIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("page.inbox.forward")}>
                <IconButton>
                  <ForwardIcon />
                </IconButton>
              </Tooltip>
            </Box>
          ) : null}
          <Box sx={{ fontSize: "0.875rem" }}>
            {formatDate(
              message.dateSent,
              "ddd MMM D, h:mm a",
              user?.organization?.timeZone ?? "Etc/UTC"
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "0.5rem",
            color: (theme) => theme.fielderColors.mutedText,
          }}
        >
          <Box component="span">{t("page.inbox.from")}:</Box>
          <Box sx={{ display: "flex", flexDirection: "row", gap: "0.25rem" }}>
            <Box component="span">{message.sender.name}</Box>
            <Box component="span">({message.sender.messageAddress})</Box>
          </Box>
        </Box>
        {message.direction === MessageDirection.OUTGOING ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "0.5rem",
              color: (theme) => theme.fielderColors.mutedText,
            }}
          >
            <Box component="span">{t("page.inbox.to")}:</Box>
            {message.toRecipients?.map((recipient, index) => (
              <Box
                key={recipient.id}
                sx={{ display: "flex", flexDirection: "row", gap: "0.25rem" }}
              >
                <Box component="span">{recipient.name}</Box>
                <Box component="span">
                  ({recipient.messageAddress})
                  {index < message.toRecipients?.length - 1 ? ", " : null}
                </Box>
              </Box>
            ))}
          </Box>
        ) : null}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "0.5rem",
            color: (theme) => theme.fielderColors.mutedText,
          }}
        >
          <Box component="span">{t("page.inbox.subject")}:</Box>
          <Box sx={{ display: "flex", flexDirection: "row", gap: "0.25rem" }}>
            <Box component="span">{message.subject}</Box>
          </Box>
        </Box>
        <Box sx={{ marginRight: "1rem", marginTop: "1.25rem" }}>
          {message.bodyHtml ? (
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message.bodyHtml) }} />
          ) : (
            message.bodyPlain
          )}
        </Box>
      </Box>
    </Box>
  )
}
