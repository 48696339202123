/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef, useCallback } from "react"
import * as Sentry from "@sentry/react"
import { useTranslation } from "react-i18next"
import dayjs, { type Dayjs } from "dayjs"
import { Column, DataSheetGrid, floatColumn, intColumn, keyColumn } from "react-datasheet-grid"
import { useMutation, useQuery, gql, NetworkStatus } from "@apollo/client"
import { CREATE_CHECKLIST } from "~/queries/createChecklist"
import { CREATE_CHECKLIST_AND_LOAD_PDF } from "~/queries/createChecklistAndLoadPdf"
import { CREATE_CHECKLIST_LINE_ITEM_NOTE } from "~/queries/createChecklistLineItemNote"
import { EDIT_CHECKLIST } from "~/queries/editChecklist"
import { EDIT_CHECKLIST_AND_LOAD_PDF } from "~/queries/editChecklistAndLoadPdf"
import FullLineItem from "~/queries/fragments/checklistLineItem"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined"
import FormControl from "@mui/material/FormControl"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Paper from "@mui/material/Paper"
import Divider from "@mui/material/Divider"
import Button from "@mui/material/Button"
import ButtonGroup from "@mui/material/ButtonGroup"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Grow from "@mui/material/Grow"
import Collapse from "@mui/material/Collapse"
import Popper from "@mui/material/Popper"
import MenuItem from "@mui/material/MenuItem"
import MenuList from "@mui/material/MenuList"
import FielderTextField from "~/components/FielderTextField"
import ConfirmationDialog from "~/components/ConfirmationDialog"
import SnackbarMessage from "~/components/SnackbarMessage"
import MultiUserSelect from "~/components/MultiUserSelect"
import SectionHeader from "~/components/SectionHeader"
import SectionContent from "~/components/SectionContent"
import ChecklistDiagramEditor from "~/components/checklist/ChecklistDiagramEditor"
import RegularReferenceTable from "~/components/checklist/RegularReferenceTable"
import MobileHomeReferenceTable from "~/components/checklist/MobileHomeReferenceTable"
import SaveAsTemplateDialog from "~/components/checklist/SaveAsTemplateDialog"
import LoadFromTemplateDialog from "~/components/checklist/LoadFromTemplateDialog"
import {
  HelixTypeOptions,
  PileTypeOptions,
  calculateAchievedCompressionCapacity,
  calculateAchievedTensionCapacity,
  calculateRequiredCompressionCapacity,
  calculateRequiredTorque,
  getKtFactor,
} from "~/components/checklist/util"
import { asInt, isNumeric, parseGraphQLErrorCode } from "~/util"
import { useAuth } from "~/context/AuthContext"
import {
  Checklist,
  ChecklistLineItem,
  ChecklistLineItemNote,
  ChecklistTemplateLineItemFormInput,
  ChecklistType,
  Job,
  PileType,
  Snack,
  User,
} from "~/types"
import { usePrompt } from "~/hooks/usePrompt"
import { CellWithId } from "react-datasheet-grid/dist/types"
import ChecklistLineNotes from "~/components/checklist/ChecklistLineNotes"
import { selectColumn } from "./SelectColumn"
import { InstallationWorksheetContextMenu } from "./InstallationWorksheetContextMenu"

import "react-datasheet-grid/dist/style.css"
import "./installation-checklist-datagrid.css"
import { useHotkeys } from "react-hotkeys-hook"
import { SEND_JOB_EMAIL } from "~/queries/sendJobEmail"
import JobEmailDialog from "./JobEmailDialog"
import ExpandMore from "~/components/ExpandMore"

const GET_CHECKLIST = gql`
  query GetChecklist($jobId: ID!) {
    getJobById(id: $jobId) {
      id
      checklist {
        id
        type
        diagram
        projectDescription
        installers {
          id
          firstName
          lastName
        }
        installationEquipment
        installationDate
        lineItems {
          ...FullLineItem
        }
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
        }
      }
    }
  }
  ${FullLineItem}
`

const DELETE_CHECKLIST_LINE_ITEM_NOTE = gql`
  mutation DeleteChecklistLineItemNote($id: ID!) {
    deleteChecklistLineItemNote(id: $id) {
      checklistLineItemNote {
        id
      }
    }
  }
`

const EDIT_CHECKLIST_LINE_ITEM_NOTE = gql`
  mutation EditChecklistLineItemNote($id: ID!, $text: String!) {
    editChecklistLineItemNote(input: { id: $id, text: $text }) {
      checklistLineItemNote {
        id
        text
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
        }
      }
    }
  }
`

function openPdf(pdfString: string) {
  // window.open(url, "_blank", "width=1200,height=900")
  if (!pdfString) {
    return
  }
  const byteCharacters = atob(pdfString)
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)
  const file = new Blob([byteArray], { type: "application/pdf;base64" })
  const fileURL = URL.createObjectURL(file)
  window.open(fileURL)
}

function renderReferenceTable(type: ChecklistType): JSX.Element | null {
  if (type === ChecklistType.REGULAR) {
    return <RegularReferenceTable />
  } else if (type === ChecklistType.MOBILE_HOME) {
    return <MobileHomeReferenceTable />
  } else {
    return null
  }
}

interface JobChecklistTabProps {
  readonly job: Job
}

function JobChecklistTab({ job }: JobChecklistTabProps) {
  const { t } = useTranslation()
  const { user } = useAuth()
  const [emailDialogOpen, setEmailDialogOpen] = useState<boolean>(false)
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false)
  const [type, setType] = useState<ChecklistType>(ChecklistType.REGULAR)
  const [installationDate, setInstallationDate] = useState<Dayjs | null>(null)
  const [installers, setInstallers] = useState<User[]>([])
  const [projectDescription, setProjectDescription] = useState<string>("")
  const [installationEquipment, setInstallationEquipment] = useState<string>("")
  const [checklist, setChecklist] = useState<Checklist>({})
  const [snack, setSnack] = useState<Snack>()
  const [noteLineItem, setNoteLineItem] = useState<ChecklistLineItem | null>() // store a temporary reference to the pile that a note is being added to
  const [actionMenuOpen, setActionMenuOpen] = useState<boolean>(false)
  const [saveAsTemplateDialogOpen, setSaveAsTemplateDialogOpen] = useState<boolean>(false)
  const [loadFromTemplateDialogOpen, setLoadFromTemplateDialogOpen] = useState<boolean>(false)
  const [deleteLineItemNoteLoadingIds, setDeleteLineItemNoteLoadingIds] = useState<string[]>([])
  const [editLineItemNoteLoadingIds, setEditLineItemNoteLoadingIds] = useState<string[]>([])
  const [activeColumn, setActiveColumn] = useState<CellWithId | null>(null)
  const [showPileNotes, setShowPileNotes] = useState<boolean>(true)
  const [showPileSpecs, setShowPileSpecs] = useState<boolean>(true)
  const editorRef = useRef()
  const actionMenuAnchorRef = useRef(null)

  usePrompt(t("messages.unsavedChangesNavPrompt"), isDirty)

  // setup a keyboard shortcut to save the checklist. ctrl+s or cmd+s.
  useHotkeys("mod+s", (e) => {
    e.preventDefault()
    handleSave(checklist)
  })

  const { loading: getChecklistLoading, networkStatus: getChecklistNetworkStatus } = useQuery(
    GET_CHECKLIST,
    {
      variables: {
        jobId: job?.id,
      },
      skip: !job?.id,
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        const checklistData = data?.getJobById?.checklist
        if (checklistData) {
          processChecklistData(checklistData)
        }
      },
      onError: (error) => {
        Sentry.captureException(error)
        const errorCode = parseGraphQLErrorCode(error)
        setSnack({ messageKey: errorCode, variant: "error" })
      },
    }
  )

  const [createChecklist, { loading: createChecklistLoading }] = useMutation(CREATE_CHECKLIST, {
    onCompleted: (data) => {
      const checklistData = data?.createChecklist?.checklist
      if (checklistData) {
        processChecklistData(checklistData)
      }
      setIsDirty(false)
      setSnack({ messageKey: "messages.changesSaved", variant: "success" })
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  const [createChecklistAndLoadPdf, { loading: createChecklistAndLoadPdfLoading }] = useMutation(
    CREATE_CHECKLIST_AND_LOAD_PDF,
    {
      onCompleted: (data) => {
        const checklistData = data?.createChecklist?.checklist
        if (checklistData) {
          processChecklistData(checklistData)
          openPdf(checklistData.pdfFile)
        }
        setIsDirty(false)

        setSnack({ messageKey: "messages.changesSaved", variant: "success" })
      },
      onError: (error) => {
        Sentry.captureException(error)
        const errorCode = parseGraphQLErrorCode(error)
        setSnack({ messageKey: errorCode, variant: "error" })
      },
    }
  )

  const [editChecklist, { loading: editChecklistLoading }] = useMutation(EDIT_CHECKLIST, {
    onCompleted: (data) => {
      const checklistData = data?.editChecklist?.checklist
      if (checklistData) {
        processChecklistData(checklistData)
        setIsDirty(false)
      }
      setSnack({ messageKey: "messages.changesSaved", variant: "success" })
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  const [editChecklistAndLoadPdf, { loading: editChecklistAndLoadPdfLoading }] = useMutation(
    EDIT_CHECKLIST_AND_LOAD_PDF,
    {
      onCompleted: (data) => {
        const checklistData = data?.editChecklist?.checklist
        if (checklistData) {
          processChecklistData(checklistData)
          setIsDirty(false)
          openPdf(checklistData.pdfFile)
        }
      },
      onError: (error) => {
        Sentry.captureException(error)
        const errorCode = parseGraphQLErrorCode(error)
        setSnack({ messageKey: errorCode, variant: "error" })
      },
    }
  )

  const [createChecklistLineItemNote, { loading: addNoteLoading }] = useMutation(
    CREATE_CHECKLIST_LINE_ITEM_NOTE,
    {
      onCompleted: (data) => {
        const noteData = data?.createChecklistLineItemNote?.checklistLineItemNote
        const newLineItems = [...(checklist.lineItems ?? [])] as ChecklistLineItem[]
        const index = newLineItems.findIndex((li) => li.id === noteLineItem?.id)
        const lineItem = newLineItems[index]

        if (lineItem) {
          const updatedNotes = [
            {
              id: noteData.id,
              text: noteData.text,
              createdBy: noteData.createdBy,
              createdAt: noteData.createdAt,
            },
            ...(lineItem.notes ?? []),
          ] as ChecklistLineItemNote[]
          newLineItems[index] = {
            ...lineItem,
            notes: updatedNotes,
          }
          setChecklist((prev) => ({
            ...prev,
            lineItems: newLineItems,
          }))
        }
        setNoteLineItem(null)
      },
      onError: (error) => {
        Sentry.captureException(error)
        const errorCode = parseGraphQLErrorCode(error)
        setSnack({ messageKey: errorCode, variant: "error" })
      },
    }
  )

  const [deleteChecklistLineItemNote] = useMutation(DELETE_CHECKLIST_LINE_ITEM_NOTE, {
    onCompleted: (data) => {
      const deletedNoteId = data.deleteChecklistLineItemNote.checklistLineItemNote.id
      const lineItems = checklist.lineItems ?? []
      const updatedLineItems: ChecklistLineItem[] = lineItems.map((li: ChecklistLineItem) => {
        if (li.notes?.some((n) => n.id === deletedNoteId)) {
          const updatedNotes = li.notes?.filter(
            (n: ChecklistLineItemNote) => n.id !== deletedNoteId
          )
          return {
            ...li,
            notes: updatedNotes,
          }
        } else {
          return li
        }
      })
      setChecklist((prev) => ({
        ...prev,
        lineItems: updatedLineItems,
      }))
      setTimeout(
        () => setDeleteLineItemNoteLoadingIds((ids) => ids.filter((id) => id !== deletedNoteId)),
        100
      )
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
    refetchQueries: () => {
      return ["GetChecklist"]
    },
  })

  const [editChecklistLineItemNote] = useMutation(EDIT_CHECKLIST_LINE_ITEM_NOTE, {
    onCompleted: (data) => {
      const editedNote = data.editChecklistLineItemNote.checklistLineItemNote
      setTimeout(
        () => setEditLineItemNoteLoadingIds((ids) => ids.filter((id) => id !== editedNote.id)),
        100
      )
    },
    onError: (error) => {
      console.error("error: ", error)
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
    refetchQueries: () => {
      return ["GetChecklist"]
    },
  })

  const [sendChecklist, { loading: sendChecklistLoading }] = useMutation(SEND_JOB_EMAIL, {
    onCompleted: () => {
      setEmailDialogOpen(false)
      setSnack({ messageKey: "messages.estimateSent", variant: "success" })
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
    refetchQueries: () => {
      return ["AllEstimatesForJob"]
    },
  })

  const loading =
    getChecklistLoading ||
    createChecklistLoading ||
    editChecklistLoading ||
    createChecklistAndLoadPdfLoading ||
    editChecklistAndLoadPdfLoading

  function processChecklistData(checklistData) {
    setInstallationDate(
      checklistData.installationDate ? dayjs(checklistData.installationDate) : null
    )
    setInstallers(checklistData.installers ?? [])
    setType(checklistData.type)
    setProjectDescription(checklistData.projectDescription ?? "")
    setInstallationEquipment(checklistData.installationEquipment ?? "")
    const currentPiles = checklistData.lineItems
      .map((li) => ({
        id: li.id,
        diagramId: li.diagramId,
        number: li.number,
        achievedCompressionCapacity: li.achievedCompressionCapacity,
        achievedTensionCapacity: li.achievedTensionCapacity,
        achievedTorque: li.achievedTorque,
        cutOffElevation: li.cutOffElevation,
        helixUsed: li.helixUsed,
        pileInclination: li.pileInclination,
        pileUsed: li.pileUsed,
        requiredCompressionCapacity: li.requiredCompressionCapacity,
        requiredTorque: li.requiredTorque,
        totalPileLength: li.totalPileLength,
        notes: li.notes
          ?.map((n: ChecklistLineItemNote) => ({
            id: n.id,
            createdAt: n.createdAt,
            createdBy: { ...n.createdBy },
            text: n.text,
          }))
          .sort(
            (a: ChecklistLineItemNote, b: ChecklistLineItemNote) =>
              Date.parse(b.createdAt) - Date.parse(a.createdAt)
          ),
      }))
      .sort(
        (a: ChecklistLineItem, b: ChecklistLineItem) =>
          (asInt(a.number) ?? 0) - (asInt(b.number) ?? 0)
      )

    setChecklist({
      id: checklistData.id,
      diagram: checklistData.diagram,
      lineItems: currentPiles,
    })

    editorRef.current?.fromJSON(checklistData.diagram)
  }

  /**
   * Add a new pile (line item) to the checklist.
   * @param lineItem - an object with the following properties: number, diagramId
   *  These props are set by the caller, which is ChecklistDiagramEditor.
   * @param diagramJson
   */
  const handleAddPile = useCallback(
    (lineItem: ChecklistTemplateLineItemFormInput, diagramJson: any) => {
      lineItem.pileUsed = "178"
      lineItem.helixUsed = "9"
      lineItem.safetyFactor = 2 // SF; kind of a "fudge" factor to account for uncertainty of soil conditions
      lineItem.requiredTorque = String(
        calculateRequiredTorque(
          lineItem.pileUsed as PileType,
          asInt(lineItem.requiredCompressionCapacity) ?? 0,
          2
        )
      )
      const lines = [...(checklist.lineItems ?? []), lineItem]

      setChecklist((prev) => ({
        ...prev,
        diagram: JSON.stringify(diagramJson),
        lineItems: lines as ChecklistLineItem[],
      }))
      setIsDirty(true)
    },
    [checklist.lineItems]
  )

  const handleRemovePiles = useCallback(
    (pileDiagramIds: string[]) => {
      let jsonString = ""
      pileDiagramIds.forEach((id) => {
        jsonString = editorRef.current?.removePile(id)
      })

      const newLineItems = (checklist.lineItems ?? [])
        .filter((li) => !pileDiagramIds.includes(li.diagramId))
        .sort((a, b) => (asInt(a.number) ?? 0) - (asInt(b.number) ?? 0))
        .map((li, idx) => ({
          ...li,
          number: idx + 1,
        }))

      setChecklist((prev) => {
        const c = {
          ...prev,
          lineItems: newLineItems,
          diagram: jsonString,
        }

        return c
      })

      setIsDirty(true)
    },
    [checklist.lineItems]
  )

  const handleAddNote = (pile: ChecklistLineItem, note: string) => {
    const lineItem = checklist.lineItems?.find((li) => {
      if (pile.id) {
        return li.id === pile.id
      } else {
        return li.number === pile.number
      }
    })

    if (lineItem?.id) {
      setNoteLineItem(pile)
      setTimeout(() => {
        createChecklistLineItemNote({
          variables: {
            checklistId: checklist.id,
            checklistLineItemId: lineItem.id,
            text: note,
          },
        })
      }, 100)
    } else if (lineItem) {
      // the user hasn't saved the line item yet, so they can only add a single note.
      // update the line item in the checklist state, and then save the entire checklist
      const updatedChecklist = {
        ...checklist,
        lineItems: (checklist.lineItems ?? []).map((li) =>
          li.number === lineItem.number ? { ...lineItem, note } : li
        ),
      }
      setChecklist(updatedChecklist)
      handleSave(updatedChecklist)
    }
  }

  const handleEditNote = (noteId: string, updatedText: string) => {
    setEditLineItemNoteLoadingIds([...editLineItemNoteLoadingIds, noteId])
    editChecklistLineItemNote({ variables: { id: noteId, text: updatedText } })
  }

  const handleDeleteNote = (noteId: string) => {
    setDeleteLineItemNoteLoadingIds([...deleteLineItemNoteLoadingIds, noteId])
    deleteChecklistLineItemNote({ variables: { id: noteId } })
  }

  /**
   * The diagram was modified in some way that doesn't affect line item data
   * (although it might be that a line item diagram object was moved around)
   */
  const handleUpdateDiagram = useCallback((diagramJson: any) => {
    setChecklist((prev) => {
      return {
        ...prev,
        diagram: JSON.stringify(diagramJson),
      }
    })
    setIsDirty(true)
  }, [])

  /**
   * This function takes a checklist object as an argument rather than just
   * working on the `checklist` state variable because in some cases you need
   * to modify the checklist and immediately save. If you just update the checklist
   * state object via setChecklist and then immediately call this function expecting
   * the `checklist` state object to be up-to-date, you're gonna have a bad time.
   * There needs to be a re-render cycle after calling setChecklist before the checklist
   * state object reflects your changes.
   * However, this function can be called with no argument. If the updatedChecklist argument
   * is not provided, then it will use the `checklist` state variable as-is.
   */
  function handleSave(updatedChecklist?: Checklist, generatePDF = false) {
    const updated = updatedChecklist ? updatedChecklist : checklist

    const piles = updated.lineItems?.map((pile: ChecklistLineItem) => {
      const requiredCompressionCapacity = asInt(pile.requiredCompressionCapacity)
      const requiredTorque = asInt(pile.requiredTorque)
      const totalPileLength = parseFloat(pile.totalPileLength)
      const cutOffElevation = parseFloat(pile.cutOffElevation)
      const pileInclination = parseFloat(pile.pileInclination)
      const achievedTorque = asInt(pile.achievedTorque)

      return {
        diagramId: pile.diagramId,
        number: pile.number,
        requiredCompressionCapacity: requiredCompressionCapacity ?? null,
        achievedCompressionCapacity: pile.achievedCompressionCapacity ?? null,
        requiredTorque: requiredTorque,
        achievedTorque: achievedTorque ?? null,
        pileUsed: pile.pileUsed,
        helixUsed: pile.helixUsed,
        totalPileLength: totalPileLength ?? null,
        cutOffElevation: cutOffElevation ?? null,
        pileInclination: pileInclination ?? null,
        achievedTensionCapacity: pile.achievedTensionCapacity ?? null,
        note: pile.note,
      }
    })

    const variables = {
      ...updated,
      jobId: job.id,
      type,
      projectDescription,
      installationDate: installationDate
        ? dayjs(installationDate).startOf("day").toISOString()
        : undefined,
      installerUserIds: installers?.map((i) => i.id) || undefined,
      installationEquipment,
      diagram: editorRef.current?.toJSON(),
      lineItems: piles,
    }

    if (updated?.id) {
      generatePDF ? editChecklistAndLoadPdf({ variables }) : editChecklist({ variables })
    } else {
      generatePDF ? createChecklistAndLoadPdf({ variables }) : createChecklist({ variables })
    }
  }

  function renderSaveButton(style: React.CSSProperties | undefined) {
    return (
      <Button
        color="primary"
        data-testid="saveChecklistButton"
        disabled={loading}
        onClick={() => handleSave(checklist)}
        style={{
          fontWeight: "bold",
          minWidth: 135,
          ...style,
        }}
        variant="contained"
      >
        {createChecklistLoading ||
        createChecklistAndLoadPdfLoading ||
        editChecklistLoading ||
        editChecklistAndLoadPdfLoading ||
        getChecklistNetworkStatus === NetworkStatus.refetch ? (
          <CircularProgress color="secondary" size={20} thickness={6.0} />
        ) : (
          <span>{t("saveChecklist")}</span>
        )}
      </Button>
    )
  }

  const columns: Column<ChecklistLineItem>[] = [
    {
      ...keyColumn<ChecklistLineItem, "pileUsed">(
        "pileUsed",
        selectColumn({ options: PileTypeOptions })
      ),
      title: t("page.checklist.pileUsed"),
      deleteValue: ({ rowData }) => {
        return {
          ...rowData,
          pileUsed: "",
        }
      },
      copyValue: ({ rowData }) =>
        PileTypeOptions.find((option) => option.value === rowData.pileUsed)?.value ?? null,
      pasteValue: ({ rowData, value }) => {
        return {
          ...rowData,
          pileUsed: PileTypeOptions.find((option) => option.value === value)?.value ?? "",
        }
      },
    },
    {
      ...keyColumn<ChecklistLineItem, "helixUsed">(
        "helixUsed",
        selectColumn({ options: HelixTypeOptions })
      ),
      title: t("page.checklist.helixUsed"),
      deleteValue: ({ rowData }) => {
        return {
          ...rowData,
          helixUsed: "",
        }
      },
      copyValue: ({ rowData }) =>
        HelixTypeOptions.find((option) => option.value === rowData.helixUsed)?.value ?? null,
      pasteValue: ({ rowData, value }) => {
        return {
          ...rowData,
          helixUsed: HelixTypeOptions.find((option) => option.value === value)?.value ?? "",
        }
      },
    },
    {
      ...keyColumn<ChecklistLineItem, "requiredCompressionCapacity">(
        "requiredCompressionCapacity",
        intColumn
      ),
      title: t("page.checklist.requiredCompressionCapacity"),
    },
    {
      ...keyColumn<ChecklistLineItem, "requiredTorque">("requiredTorque", intColumn),
      title: t("page.checklist.requiredTorque"),
    },
    {
      ...keyColumn<ChecklistLineItem, "totalPileLength">("totalPileLength", floatColumn),
      title: t("page.checklist.totalPileLength"),
      headerClassName: "optional-header",
    },
    {
      ...keyColumn<ChecklistLineItem, "cutOffElevation">("cutOffElevation", floatColumn),
      title: t("page.checklist.cutOffElevation"),
      headerClassName: "optional-header",
    },
    {
      ...keyColumn<ChecklistLineItem, "pileInclination">("pileInclination", floatColumn),
      title: t("page.checklist.pileInclination"),
      headerClassName: "optional-header",
    },
    {
      ...keyColumn<ChecklistLineItem, "achievedTorque">("achievedTorque", intColumn),
      title: t("page.checklist.achievedTorque"),
    },
    {
      ...keyColumn<ChecklistLineItem, "achievedCompressionCapacity">(
        "achievedCompressionCapacity",
        intColumn
      ),
      title: t("page.checklist.achievedCompressionCapacity"),
      disabled: true,
    },
    {
      ...keyColumn<ChecklistLineItem, "achievedTensionCapacity">(
        "achievedTensionCapacity",
        intColumn
      ),
      title: t("page.checklist.achievedTensionCapacity"),
      disabled: true,
    },
  ]

  return (
    <Box
      sx={{
        paddingBottom: "400px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "0 auto",
        width: "1161px",
        maxwidth: "1161px",
        minWidth: "1161px",
      }}
    >
      {showConfirmationDialog ? (
        <ConfirmationDialog
          description={t("component.clearChecklistDialog.confirmationPrompt")}
          onCancel={() => setShowConfirmationDialog(false)}
          onConfirm={() => {
            setShowConfirmationDialog(false)
            const emptyChecklist: Checklist = {
              id: checklist.id,
              projectDescription: "",
              installers: [],
              installationEquipment: "",
              installationDate: "",
              lineItems: [],
            }
            setActionMenuOpen(false)
            editorRef.current?.clear()
            handleSave(emptyChecklist)
          }}
        />
      ) : null}
      {saveAsTemplateDialogOpen ? (
        <SaveAsTemplateDialog
          checklist={checklist}
          onCancel={() => {
            setSaveAsTemplateDialogOpen(false)
          }}
          onSave={(success, messageKey, messageOptions) => {
            if (success) {
              setSnack({ messageKey, messageOptions, variant: "success" })
            } else {
              setSnack({ messageKey, messageOptions, variant: "error" })
            }
            setSaveAsTemplateDialogOpen(false)
          }}
        />
      ) : null}
      {loadFromTemplateDialogOpen ? (
        <LoadFromTemplateDialog
          onCancel={() => {
            setLoadFromTemplateDialogOpen(false)
          }}
          onSelectTemplate={(selectedTemplate) => {
            const sanitized = {
              ...selectedTemplate,
              id: checklist?.id,
              projectDescription,
              installationDate,
              installationEquipment,
              installers,
              lineItems: selectedTemplate.lineItems.map((li) => {
                // eslint-disable-next-line no-unused-vars
                const { id, __typename, ...rest } = li
                return rest
              }),
            }
            setIsDirty(true)
            processChecklistData(sanitized)
            setLoadFromTemplateDialogOpen(false)
          }}
        />
      ) : null}
      {emailDialogOpen && checklist ? (
        <JobEmailDialog
          checklist={checklist}
          job={job}
          loading={sendChecklistLoading}
          onCancel={() => {
            setEmailDialogOpen(false)
          }}
          onSend={(payload) => {
            sendChecklist({ variables: payload })
          }}
        />
      ) : null}
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <Paper
        sx={{
          width: "100%",
          marginBottom: "1rem",
          maxWidth: "1161px",
        }}
      >
        <SectionHeader>
          <label>{t("basicInfo")}</label>
          <ButtonGroup
            aria-label="split button"
            color="primary"
            ref={actionMenuAnchorRef}
            variant="contained"
          >
            {renderSaveButton({
              borderRadius: "4px 0 0 4px",
              boxShadow: "none",
            })}
            <Button
              aria-controls={actionMenuOpen ? "split-button-menu" : undefined}
              aria-expanded={actionMenuOpen ? "true" : undefined}
              aria-haspopup="menu"
              aria-label="select an option"
              color="primary"
              disabled={loading}
              onClick={() => setActionMenuOpen((prevOpen) => !prevOpen)}
              size="small"
              style={{
                borderRadius: "0 4px 4px 0",
              }}
              variant="contained"
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper
            anchorEl={actionMenuAnchorRef.current}
            disablePortal
            open={actionMenuOpen}
            placement="bottom"
            role={undefined}
            style={{
              opacity: 1,
              zIndex: 100,
              minWidth: 178,
            }}
            transition
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Paper>
                  <ClickAwayListener
                    onClickAway={(event) => {
                      if (actionMenuAnchorRef.current?.contains(event.target)) {
                        return
                      }
                      setActionMenuOpen(false)
                    }}
                  >
                    <MenuList id="split-button-menu">
                      <MenuItem
                        disabled={false}
                        key="generatePDF"
                        onClick={() => {
                          setActionMenuOpen(false)
                          handleSave(checklist, true)
                        }}
                      >
                        {t("generatePDF")}
                      </MenuItem>
                      <MenuItem
                        disabled={!checklist.id}
                        key="sendChecklist"
                        onClick={() => {
                          setActionMenuOpen(false)
                          setEmailDialogOpen(true)
                        }}
                      >
                        {t("page.checklist.sendChecklist")}
                      </MenuItem>
                      <MenuItem
                        disabled={false}
                        key="saveAsTemplate"
                        onClick={() => {
                          setActionMenuOpen(false)
                          setSaveAsTemplateDialogOpen(true)
                        }}
                      >
                        {t("saveAsTemplate")}
                      </MenuItem>
                      <MenuItem
                        disabled={false}
                        key="loadFromTemplate"
                        onClick={() => {
                          setActionMenuOpen(false)
                          setLoadFromTemplateDialogOpen(true)
                        }}
                      >
                        {t("loadFromTemplate")}
                      </MenuItem>
                      <MenuItem
                        disabled={false}
                        key="clearChecklist"
                        onClick={() => setShowConfirmationDialog(true)}
                      >
                        {t("page.checklist.clearChecklist")}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </SectionHeader>
        <Divider />
        <SectionContent sx={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
          <FormControl fullWidth sx={classes.formControl}>
            <DatePicker
              aria-label={t("installationDate")}
              format={t("format:dateFormat.short") as string}
              label={t("installationDate")}
              onChange={(val) => {
                setIsDirty(true)
                setInstallationDate(val)
              }}
              slotProps={{
                textField: {
                  inputProps: {
                    // disableUnderline: true,
                  },
                  error: false,
                  fullWidth: true,
                  required: false,
                },
              }}
              slots={{
                textField: FielderTextField,
              }}
              value={installationDate}
            />
          </FormControl>
          <FormControl fullWidth sx={classes.formControl}>
            <MultiUserSelect
              aria-label={t("installers")}
              id="installers"
              label={t("installers")}
              name="installers"
              onChange={(users) => {
                setIsDirty(true)
                setInstallers(users ?? ([] as User[]))
              }}
              roleNames={["FIELD_TECH"]}
              selectedUsers={installers}
            />
          </FormControl>
          <FormControl fullWidth sx={classes.formControl}>
            <FielderTextField
              data-testid="projectDescription-Field"
              fullWidth
              id="projectDescription"
              inputProps={{
                maxLength: 1000,
              }}
              label={t("projectDescription")}
              maxRows="3"
              minRows="3"
              multiline
              name="projectDescription"
              onChange={(e) => {
                setIsDirty(true)
                setProjectDescription(e.target.value)
              }}
              value={projectDescription}
            />
          </FormControl>

          <FormControl fullWidth sx={classes.formControl}>
            <FielderTextField
              data-testid="installationEquipment-Field"
              fullWidth
              id="installationEquipment"
              inputProps={{
                maxLength: 1000,
              }}
              label={t("installationEquipment")}
              maxRows="3"
              minRows="3"
              multiline
              name="installationEquipment"
              onChange={(e) => {
                setIsDirty(true)
                setInstallationEquipment(e.target.value)
              }}
              value={installationEquipment}
            />
          </FormControl>
        </SectionContent>
      </Paper>
      <Paper
        sx={{
          width: "100%",
          maxWidth: "1161px",
          backgroundColor: "#fefefe",
          marginBottom: "1rem",
          paddingBottom: "0.125rem",
        }}
      >
        <SectionHeader>
          <label>{t("page.checklist.diagram")}</label>
        </SectionHeader>
        <Divider sx={{ slize: "#212121" }} />
        <ChecklistDiagramEditor
          loading={loading}
          onAddPile={handleAddPile}
          onRemovePiles={handleRemovePiles}
          onUpdateDiagram={handleUpdateDiagram}
          ref={editorRef}
        />
      </Paper>
      <Paper sx={{ width: "100%", marginBottom: "1rem", paddingBottom: "1rem" }}>
        <SectionHeader>
          <Box
            onClick={() => setShowPileSpecs((prev) => !prev)}
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Box component="label" sx={{ cursor: "pointer" }}>
              {t("page.checklist.pileSpecs")}
            </Box>
            <ExpandMore
              aria-expanded={showPileSpecs}
              aria-label={t("page.checklist.pileSpecs") as string}
              expand={showPileSpecs}
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </Box>
        </SectionHeader>
        <Collapse in={showPileSpecs}>
          {checklist.lineItems?.length > 0 ? (
            <DataSheetGrid
              addRowsComponent={false}
              columns={columns}
              contextMenuComponent={InstallationWorksheetContextMenu}
              disableContextMenu={false}
              headerRowHeight={60}
              height={2000}
              onActiveCellChange={(arg: { cell: CellWithId | null }) => {
                setActiveColumn(arg.cell)
              }}
              onChange={(newValue, operations) => {
                for (const operation of operations) {
                  switch (operation.type) {
                    case "UPDATE": {
                      const updatedRows = newValue.slice(
                        operation.fromRowIndex,
                        operation.toRowIndex
                      )
                      if (activeColumn?.colId === "pileUsed") {
                        for (const updatedRow of updatedRows) {
                          const pileType =
                            PileTypeOptions.find((o) => o.value === updatedRow.pileUsed) ??
                            PileTypeOptions[0]

                          if (updatedRow.requiredCompressionCapacity) {
                            updatedRow.requiredTorque = calculateRequiredTorque(
                              pileType.value as PileType,
                              updatedRow.requiredCompressionCapacity
                            )
                          }

                          if (updatedRow.achievedTorque) {
                            updatedRow.achievedCompressionCapacity =
                              calculateAchievedCompressionCapacity(
                                updatedRow.achievedTorque ?? 0,
                                2,
                                getKtFactor(updatedRow.pileUsed)
                              )
                          }
                        }
                      } else if (activeColumn?.colId === "requiredCompressionCapacity") {
                        for (const updatedRow of updatedRows) {
                          const pileType =
                            PileTypeOptions.find((o) => o.value === updatedRow.pileUsed) ??
                            PileTypeOptions[0]
                          const newRCC = updatedRow.requiredCompressionCapacity ?? 0
                          updatedRow.requiredTorque = calculateRequiredTorque(
                            pileType.value as PileType,
                            newRCC
                          )
                        }
                      } else if (activeColumn?.colId === "requiredTorque") {
                        for (const updatedRow of updatedRows) {
                          const pileType =
                            PileTypeOptions.find((o) => o.value === updatedRow.pileUsed) ??
                            PileTypeOptions[0]
                          const newRequiredTorque = updatedRow.requiredTorque ?? 0
                          updatedRow.requiredCompressionCapacity =
                            calculateRequiredCompressionCapacity(
                              pileType.value as PileType,
                              newRequiredTorque
                            )
                        }
                      } else if (activeColumn?.colId === "totalPileLength") {
                        for (const updatedRow of updatedRows) {
                          if (isNumeric(updatedRow.achievedTorque)) {
                            updatedRow.achievedCompressionCapacity =
                              calculateAchievedCompressionCapacity(
                                updatedRow.achievedTorque ?? 0,
                                2,
                                getKtFactor(updatedRow.pileUsed)
                              )
                            updatedRow.achievedTensionCapacity = calculateAchievedTensionCapacity(
                              updatedRow.totalPileLength ?? 0,
                              updatedRow.achievedCompressionCapacity
                            )
                          }
                        }
                      } else if (activeColumn?.colId === "achievedTorque") {
                        for (const updatedRow of updatedRows) {
                          updatedRow.achievedCompressionCapacity =
                            calculateAchievedCompressionCapacity(
                              updatedRow.achievedTorque ?? 0,
                              2,
                              getKtFactor(updatedRow.pileUsed)
                            )
                          updatedRow.achievedTensionCapacity = calculateAchievedTensionCapacity(
                            updatedRow.totalPileLength ?? 0,
                            updatedRow.achievedCompressionCapacity
                          )
                        }
                      }
                      setChecklist((prev) => {
                        return {
                          ...prev,
                          lineItems: newValue,
                        }
                      })
                      break
                    }
                    case "DELETE": {
                      const deletedRows = (checklist.lineItems ?? []).slice(
                        operation.fromRowIndex,
                        operation.toRowIndex
                      )
                      const pileDiagramIds = deletedRows.map((row) => row.diagramId)
                      handleRemovePiles(pileDiagramIds)
                      break
                    }
                    default:
                      break
                  }
                }
              }}
              onFocus={(arg: { cell: CellWithId }) => {
                setActiveColumn(arg.cell)
              }}
              value={checklist.lineItems}
            />
          ) : (
            <Box
              sx={{
                color: (theme) => theme.fielderColors.mutedText,
                fontSize: "1rem",
                padding: "1rem",
                textAlign: "center",
              }}
            >
              {t("page.checklist.pileSpecsEmpty")}
            </Box>
          )}
        </Collapse>
      </Paper>
      <Paper sx={{ width: "100%", marginBottom: "3rem", paddingBottom: "0.125rem" }}>
        <SectionHeader>
          <Box
            onClick={() => setShowPileNotes((prev) => !prev)}
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Box component="label" sx={{ cursor: "pointer" }}>
              {t("page.checklist.pileNotes")}
            </Box>
            <ExpandMore
              aria-expanded={showPileNotes}
              aria-label={t("page.checklist.pileNotes") as string}
              expand={showPileNotes}
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </Box>
        </SectionHeader>
        <Collapse in={showPileNotes}>
          <SectionContent>
            {(checklist.lineItems ?? []).length > 0 ? (
              (checklist.lineItems ?? []).map((pile) => {
                return (
                  <Accordion
                    disableGutters={false}
                    key={`${pile.diagramId}+${pile.number}`}
                    sx={{ backgroundColor: "#ffffff" }}
                    variant="outlined"
                  >
                    <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "1rem",
                          alignItems: "center",
                        }}
                      >
                        {t("page.checklist.notesForPileNumber", { pileNumber: pile.number })}
                        {(pile.notes ?? []).length > 0 ? (
                          <ChatOutlinedIcon
                            fontSize="small"
                            sx={{
                              fontSize: "0.875rem",
                              color: (theme) => theme.fielderColors.mutedText,
                            }}
                          />
                        ) : null}
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ChecklistLineNotes
                        addNoteLoading={addNoteLoading ? noteLineItem?.id === pile.id : false}
                        deleteNoteLoadingIds={deleteLineItemNoteLoadingIds}
                        editNoteLoadingIds={editLineItemNoteLoadingIds}
                        notes={pile.notes ?? []}
                        onAddNote={(note) => handleAddNote(pile, note)}
                        onDeleteNote={handleDeleteNote}
                        onEditNote={(noteId: string, updatedText: string) => {
                          handleEditNote?.(noteId, updatedText)
                        }}
                        user={user}
                      />
                    </AccordionDetails>
                  </Accordion>
                )
              })
            ) : (
              <Box
                sx={{
                  color: (theme) => theme.fielderColors.mutedText,
                  fontSize: "1rem",
                  padding: "1rem",
                  textAlign: "center",
                }}
              >
                {t("page.checklist.pileNotesEmpty")}
              </Box>
            )}
          </SectionContent>
        </Collapse>
      </Paper>
      <section style={{ marginTop: "0.5rem", width: "98%" }}>
        <FormControl component="fieldset">
          <legend style={{ fontSize: "1rem", fontWeight: "bold" }}>
            {t("page.checklist.selectReferenceChart")}
          </legend>
          <RadioGroup
            aria-label="reference chart type"
            name="row-radio-buttons-group"
            onChange={(e) => {
              setIsDirty(true)
              setType(e.target.value)
            }}
            row
            value={type}
          >
            <FormControlLabel
              control={<Radio />}
              label={t("checklistTypeOptions.REGULAR") as string}
              value={ChecklistType.REGULAR}
            />
            <FormControlLabel
              control={<Radio />}
              label={t("checklistTypeOptions.MOBILE_HOME") as string}
              value={ChecklistType.MOBILE_HOME}
            />
          </RadioGroup>
        </FormControl>
        {renderReferenceTable(type)}
      </section>
    </Box>
  )
}

const classes = {
  sectionColumn: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  formControl: {
    padding: "0.625rem",
  },
  metaColumn: {
    backgroundColor: "#F8DBC0",
  },
}

export default JobChecklistTab
