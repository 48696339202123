import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { AuthProvider } from "~/context/AuthContext"
import Calendar from "~/app/calendar"
import CustomerInvoice from "~/app/customerInvoice"
import CustomerEstimate from "~/app/customerEstimate"
import CustomerList from "~/app/customers/list"
import CreateCustomer from "~/app/customers/create"
import EditCustomer from "~/app/customers/edit"
import Dashboard from "~/app/dashboard"
import Inbox from "~/app/inbox"
import Dispatch from "~/app/dispatch"
import ForgotPassword from "~/app/forgotPassword"
import FranchiseeList from "~/app/franchisees/list"
import CreateFranchisee from "~/app/franchisees/create"
import CatalogItemList from "~/app/catalog/list"
import CreateCatalogItem from "~/app/catalog/create"
import EditCatalogItem from "~/app/catalog/edit"
import ImportCatalogItems from "~/app/catalog/import"
import EditFranchisee from "~/app/franchisees/edit"
import Help from "~/app/help-center/home"
import HelpVideos from "~/app/help-center/videos"
import HelpArticles from "~/app/help-center/articles"
import OrganizationItemList from "~/app/inventory/list"
import CreateOrganizationItem from "~/app/inventory/create"
import EditOrganizationItem from "~/app/inventory/edit"
import FranchiseeProductOrderList from "~/app/franchisee-orders/list"
import FranchiseeEditProductOrder from "~/app/franchisee-orders/edit"
import FranchiseeEditCart from "~/app/franchisee-orders/cart"
import FranchisorProductOrderList from "~/app/franchisor-orders/list"
import FranchisorProductOrderBoard from "~/app/franchisor-orders/board"
import FranchisorEditProductOrder from "~/app/franchisor-orders/edit"
import InvoiceList from "~/app/invoices/list"
import CreateJob from "~/app/jobs/create"
import EditJob from "~/app/jobs/edit"
import JobList from "~/app/jobs/list"
import JobBoard from "~/app/jobs/board"
import Login from "~/app/login"
import MyProfile from "~/app/profile/myProfile"
import EstimateRequestConfirmation from "~/app/estimate-requests/confirmation"
import CreateEstimateRequest from "~/app/estimate-requests/create"
import EstimateRequestDetail from "~/app/estimate-requests/detail"
import EstimateRequestList from "~/app/estimate-requests/list"
import Reports from "~/app/reports"
import ReportsHQ from "~/app/reportsHQ"
import SettingsHome from "~/app/settings/home"
import EditOrganization from "~/app/settings/organization/edit"
import ConfigurePlugin from "~/app/settings/plugins/configure"
import PluginDetails from "~/app/settings/plugins/details"
import PluginsHome from "~/app/settings/plugins/home"
import CreateRole from "~/app/settings/roles/create"
import EditRole from "~/app/settings/roles/edit"
import RoleList from "~/app/settings/roles/list"
import CreateUser from "~/app/settings/users/create"
import EditUser from "~/app/settings/users/edit"
import UserList from "~/app/settings/users/list"
import ManageCustomerFlags from "~/app/settings/customerflags/list"
import JobCategoryOptions from "~/app/settings/jobcategories/list"
import JobWorkflowsList from "~/app/settings/jobworkflows/list"
import EditJobWorkflow from "~/app/settings/jobworkflows/edit"
import Regions from "~/app/settings/regions/list"
import TaxRateGroups from "~/app/settings/taxrates/list"
import JobStatusOptions from "~/app/settings/jobstatuses/list"
import TemplatesHome from "~/app/settings/templates/index"
import ChecklistTemplates from "~/app/settings/templates/checklist/list"
import EditChecklistTemplate from "~/app/settings/templates/checklist/edit"
import EstimateTemplates from "~/app/settings/templates/estimate/list"
import EditEstimateTemplate from "~/app/settings/templates/estimate/edit"
import WorkOrderTemplates from "~/app/settings/templates/workorder/list"
import EditWorkOrderTemplate from "~/app/settings/templates/workorder/edit"
import EmailTemplates from "~/app/settings/templates/email/list"
import EditEmailTemplate from "~/app/settings/templates/email/edit"
import ContractTemplates from "~/app/settings/templates/contract/list"
import EditContractTemplate from "~/app/settings/templates/contract/edit"
import TaskList from "~/app/tasks/list"
import Unauthorized from "~/app/unauthorized"
import OrgAccountLocked from "~/app/orgAccountLocked"
import OrgAccountPending from "~/app/orgAccountPending"
import FranchiseePricing from "~/app/pricing"
import SubscriptionPaymentForm from "~/app/subscriptionPaymentForm"
import VerifyPasswordResetCode from "~/app/verifyPasswordResetCode"
import ActivateAccount from "~/app/activateAccount"
import RequireAuth from "~/components/RequireAuth"
import NotFound from "~/app/404"
import { DefaultPermission } from "~/types"
import PERMISSION_REQUIREMENTS from "~/util/permissionRequirements"

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route element={<Login />} path="/app/login" />
          <Route element={<ForgotPassword />} path="/app/forgot-password" />
          <Route
            element={<VerifyPasswordResetCode />}
            path="/app/verify-password-reset-code/:code"
          />
          <Route element={<ActivateAccount />} path="/app/activate-account/:code" />
          <Route
            element={<CreateEstimateRequest />}
            path="/app/estimate-requests/create/:organizationId"
          />
          <Route
            element={<EstimateRequestConfirmation />}
            path="/app/estimate-requests/confirmation"
          />
          <Route element={<CustomerEstimate />} path="/app/estimate/:id" />
          <Route element={<CustomerInvoice />} path="/app/invoice/:id" />
          <Route element={<Unauthorized />} path="/app/unauthorized" />
          <Route element={<OrgAccountLocked />} path="/app/org-account-locked" />
          <Route element={<OrgAccountPending />} path="/app/org-account-pending" />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.dashboard}>
                <Dashboard />
              </RequireAuth>
            }
            path="/app/dashboard"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.dashboard}>
                <Dashboard />
              </RequireAuth>
            }
            path="/app"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.inbox}>
                <Inbox />
              </RequireAuth>
            }
            path="/app/inbox"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={[DefaultPermission.UpdateOrganization]}>
                <FranchiseePricing />
              </RequireAuth>
            }
            path="/app/pricing"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={[DefaultPermission.UpdateOrganization]}>
                <SubscriptionPaymentForm />
              </RequireAuth>
            }
            path="/app/payment/:planId/:priceId"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={[]}>
                <Calendar />
              </RequireAuth>
            }
            path="/app/calendar"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.customers.list}>
                <CustomerList />
              </RequireAuth>
            }
            path="/app/customers"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.customers.list}>
                <CustomerList />
              </RequireAuth>
            }
            path="/app/customers/list"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.customers.create}>
                <CreateCustomer />
              </RequireAuth>
            }
            path="/app/customers/create"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.customers.edit}>
                <EditCustomer />
              </RequireAuth>
            }
            path="/app/customers/edit/:id"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.dispatch}>
                <Dispatch />
              </RequireAuth>
            }
            path="/app/dispatch"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.inventory.list}>
                <OrganizationItemList />
              </RequireAuth>
            }
            path="/app/inventory"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.inventory.list}>
                <OrganizationItemList />
              </RequireAuth>
            }
            path="/app/inventory/list"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.inventory.create}>
                <CreateOrganizationItem />
              </RequireAuth>
            }
            path="/app/inventory/create/:itemType"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.inventory.edit}>
                <EditOrganizationItem />
              </RequireAuth>
            }
            path="/app/inventory/edit/:id"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.productOrders}>
                <FranchiseeProductOrderList />
              </RequireAuth>
            }
            path="/app/orders"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.productOrders}>
                <FranchiseeProductOrderList />
              </RequireAuth>
            }
            path="/app/orders/list"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.productOrders}>
                <FranchiseeEditProductOrder />
              </RequireAuth>
            }
            path="/app/orders/edit/:id"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.productOrders}>
                <FranchiseeEditCart />
              </RequireAuth>
            }
            path="/app/orders/cart"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.productOrdersHQ.list}>
                <FranchisorProductOrderBoard />
              </RequireAuth>
            }
            path="/app/orders/hq"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.productOrdersHQ.list}>
                <FranchisorProductOrderList />
              </RequireAuth>
            }
            path="/app/orders/hq/list"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.productOrdersHQ.list}>
                <FranchisorProductOrderBoard />
              </RequireAuth>
            }
            path="/app/orders/hq/board"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.productOrdersHQ.edit}>
                <FranchisorEditProductOrder />
              </RequireAuth>
            }
            path="/app/orders/hq/edit/:id"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.catalog.list}>
                <CatalogItemList />
              </RequireAuth>
            }
            path="/app/catalog"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.catalog.list}>
                <CatalogItemList />
              </RequireAuth>
            }
            path="/app/catalog/list"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.catalog.create}>
                <CreateCatalogItem />
              </RequireAuth>
            }
            path="/app/catalog/create"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.catalog.edit}>
                <EditCatalogItem />
              </RequireAuth>
            }
            path="/app/catalog/edit/:id"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.catalog.import}>
                <ImportCatalogItems />
              </RequireAuth>
            }
            path="/app/catalog/import"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.franchisees.list}>
                <FranchiseeList />
              </RequireAuth>
            }
            path="/app/franchisees"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.franchisees.list}>
                <FranchiseeList />
              </RequireAuth>
            }
            path="/app/franchisees/list"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.franchisees.create}>
                <CreateFranchisee />
              </RequireAuth>
            }
            path="/app/franchisees/create"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.franchisees.edit}>
                <EditFranchisee />
              </RequireAuth>
            }
            path="/app/franchisees/edit/:id"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.jobs.list}>
                <JobBoard />
              </RequireAuth>
            }
            path="/app/jobs"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.jobs.list}>
                <JobBoard />
              </RequireAuth>
            }
            path="/app/jobs/board"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.jobs.list}>
                <JobList />
              </RequireAuth>
            }
            path="/app/jobs/list"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.jobs.create}>
                <CreateJob />
              </RequireAuth>
            }
            path="/app/jobs/create"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.jobs.edit}>
                <EditJob />
              </RequireAuth>
            }
            path="/app/jobs/edit/:id/:activeTab"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.jobs.edit}>
                <EditJob />
              </RequireAuth>
            }
            path="/app/jobs/edit/:id"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.tasks}>
                <TaskList />
              </RequireAuth>
            }
            path="/app/tasks"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.tasks}>
                <TaskList />
              </RequireAuth>
            }
            path="/app/tasks/list"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.tasks}>
                <TaskList />
              </RequireAuth>
            }
            path="/app/tasks/:id"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.invoices}>
                <InvoiceList />
              </RequireAuth>
            }
            path="/app/invoices"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.invoices}>
                <InvoiceList />
              </RequireAuth>
            }
            path="/app/invoices/list"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.reports}>
                <Reports />
              </RequireAuth>
            }
            path="/app/reports"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.reportsHQ}>
                <ReportsHQ />
              </RequireAuth>
            }
            path="/app/reports/hq"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.helpCenter}>
                <Help />
              </RequireAuth>
            }
            path="/app/help"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.helpCenter}>
                <HelpVideos />
              </RequireAuth>
            }
            path="/app/help/videos"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.helpCenter}>
                <HelpArticles />
              </RequireAuth>
            }
            path="/app/help/articles"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.estimateRequests}>
                <EstimateRequestList />
              </RequireAuth>
            }
            path="/app/estimate-requests"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.estimateRequests}>
                <EstimateRequestList />
              </RequireAuth>
            }
            path="/app/estimate-requests/list"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.estimateRequests}>
                <EstimateRequestDetail />
              </RequireAuth>
            }
            path="/app/estimate-requests/detail/:id"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={[]}>
                <SettingsHome />
              </RequireAuth>
            }
            path="/app/settings"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.settings.organization}>
                <EditOrganization />
              </RequireAuth>
            }
            path="/app/settings/organization/edit"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.settings.users.list}>
                <UserList />
              </RequireAuth>
            }
            path="/app/settings/users"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.settings.users.list}>
                <UserList />
              </RequireAuth>
            }
            path="/app/settings/users/list"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.settings.users.edit}>
                <EditUser />
              </RequireAuth>
            }
            path="/app/settings/users/edit/:id"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.settings.users.create}>
                <CreateUser />
              </RequireAuth>
            }
            path="/app/settings/users/create"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.settings.roles}>
                <RoleList />
              </RequireAuth>
            }
            path="/app/settings/roles"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.settings.roles}>
                <RoleList />
              </RequireAuth>
            }
            path="/app/settings/roles/list"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.settings.roles}>
                <EditRole />
              </RequireAuth>
            }
            path="/app/settings/roles/edit/:id"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.settings.roles}>
                <CreateRole />
              </RequireAuth>
            }
            path="/app/settings/roles/create"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.settings.customerFlags}>
                <ManageCustomerFlags />
              </RequireAuth>
            }
            path="/app/settings/customerflags"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.settings.jobCategories}>
                <JobCategoryOptions />
              </RequireAuth>
            }
            path="/app/settings/jobcategories"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.settings.jobWorkflows}>
                <JobWorkflowsList />
              </RequireAuth>
            }
            path="/app/settings/jobworkflows"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.settings.jobWorkflows}>
                <JobWorkflowsList />
              </RequireAuth>
            }
            path="/app/settings/jobworkflows/list"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.settings.jobWorkflows}>
                <EditJobWorkflow />
              </RequireAuth>
            }
            path="/app/settings/jobworkflows/edit/:id"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.settings.regions}>
                <Regions />
              </RequireAuth>
            }
            path="/app/settings/regions"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.settings.jobStatuses}>
                <JobStatusOptions />
              </RequireAuth>
            }
            path="/app/settings/jobstatuses"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.settings.taxRateGroups}>
                <TaxRateGroups />
              </RequireAuth>
            }
            path="/app/settings/taxrates"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.settings.plugins}>
                <PluginsHome />
              </RequireAuth>
            }
            path="/app/settings/plugins"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.settings.plugins}>
                <PluginDetails />
              </RequireAuth>
            }
            path="/app/settings/plugins/details/:id"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.settings.plugins}>
                <ConfigurePlugin />
              </RequireAuth>
            }
            path="/app/settings/plugins/configure/:id"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.settings.myProfile}>
                <MyProfile />
              </RequireAuth>
            }
            path="/app/settings/profile"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={[]}>
                <TemplatesHome />
              </RequireAuth>
            }
            path="/app/settings/templates"
          />
          <Route
            element={
              <RequireAuth
                requiredPermissions={PERMISSION_REQUIREMENTS.settings.checklistTemplates}
              >
                <ChecklistTemplates />
              </RequireAuth>
            }
            path="/app/settings/templates/checklist"
          />
          <Route
            element={
              <RequireAuth
                requiredPermissions={PERMISSION_REQUIREMENTS.settings.checklistTemplates}
              >
                <EditChecklistTemplate />
              </RequireAuth>
            }
            path="/app/settings/templates/checklist/edit/:id"
          />
          <Route
            element={
              <RequireAuth
                requiredPermissions={PERMISSION_REQUIREMENTS.settings.checklistTemplates}
              >
                <EditChecklistTemplate />
              </RequireAuth>
            }
            path="/app/settings/templates/checklist/create"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.settings.estimateTemplates}>
                <EstimateTemplates />
              </RequireAuth>
            }
            path="/app/settings/templates/estimate"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.settings.estimateTemplates}>
                <EstimateTemplates />
              </RequireAuth>
            }
            path="/app/settings/templates/estimate/list"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.settings.estimateTemplates}>
                <EditEstimateTemplate />
              </RequireAuth>
            }
            path="/app/settings/templates/estimate/edit/:id"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.settings.estimateTemplates}>
                <EditEstimateTemplate />
              </RequireAuth>
            }
            path="/app/settings/templates/estimate/create"
          />
          <Route
            element={
              <RequireAuth
                requiredPermissions={PERMISSION_REQUIREMENTS.settings.workOrderTemplates}
              >
                <WorkOrderTemplates />
              </RequireAuth>
            }
            path="/app/settings/templates/workorder"
          />
          <Route
            element={
              <RequireAuth
                requiredPermissions={PERMISSION_REQUIREMENTS.settings.workOrderTemplates}
              >
                <WorkOrderTemplates />
              </RequireAuth>
            }
            path="/app/settings/templates/workorder/list"
          />
          <Route
            element={
              <RequireAuth
                requiredPermissions={PERMISSION_REQUIREMENTS.settings.workOrderTemplates}
              >
                <EditWorkOrderTemplate />
              </RequireAuth>
            }
            path="/app/settings/templates/workorder/edit/:id"
          />
          <Route
            element={
              <RequireAuth
                requiredPermissions={PERMISSION_REQUIREMENTS.settings.workOrderTemplates}
              >
                <EditWorkOrderTemplate />
              </RequireAuth>
            }
            path="/app/settings/templates/workorder/create"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.settings.emailTemplates}>
                <EmailTemplates />
              </RequireAuth>
            }
            path="/app/settings/templates/email"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.settings.emailTemplates}>
                <EmailTemplates />
              </RequireAuth>
            }
            path="/app/settings/templates/email/list"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.settings.emailTemplates}>
                <EditEmailTemplate />
              </RequireAuth>
            }
            path="/app/settings/templates/email/edit/:id"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.settings.emailTemplates}>
                <EditEmailTemplate />
              </RequireAuth>
            }
            path="/app/settings/templates/email/create"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.settings.contractTemplates}>
                <ContractTemplates />
              </RequireAuth>
            }
            path="/app/settings/templates/contract"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.settings.contractTemplates}>
                <ContractTemplates />
              </RequireAuth>
            }
            path="/app/settings/templates/contract/list"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.settings.contractTemplates}>
                <EditContractTemplate />
              </RequireAuth>
            }
            path="/app/settings/templates/contract/edit/:id"
          />
          <Route
            element={
              <RequireAuth requiredPermissions={PERMISSION_REQUIREMENTS.settings.contractTemplates}>
                <EditContractTemplate />
              </RequireAuth>
            }
            path="/app/settings/templates/contract/create"
          />

          <Route element={<NotFound />} path="*" />
        </Routes>
      </AuthProvider>
    </Router>
  )
}

export default App
