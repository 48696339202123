/* eslint-disable compat/compat */
import React, { useState } from "react"
import Cookies from "js-cookie"
import * as Sentry from "@sentry/react"
import { useTranslation } from "react-i18next"
import { gql, useMutation, useQuery } from "@apollo/client"
import Box from "@mui/material/Box"
import { Theme } from "@mui/material/styles"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import ConstructionIcon from "@mui/icons-material/ConstructionOutlined"
import WarningIcon from "@mui/icons-material/WarningAmberOutlined"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import LinearProgress from "@mui/material/LinearProgress"
import CircularProgress from "@mui/material/CircularProgress"
import Modal from "@mui/material/Modal"

import { FileTypeIcon } from "~/components/icons/FileTypeIcon"
import FileSelector from "~/components/FileSelector"
import SnackbarMessage from "~/components/SnackbarMessage"
import { parseGraphQLErrorCode } from "~/util/parseGraphQLErrorCode"
import { getFilename } from "~/util/stringUtils"
import type { Attachment, Job, Snack } from "~/types"
import { AttachmentType, AttachmentPreviewSize, AttachmentStatus } from "~/types/apiTypes"
import { DefaultPermission } from "~/types/permissions"
import { useAuth } from "~/context/AuthContext"
import { set } from "lodash"

const GET_JOB_ATTACHMENTS = gql`
  query GetJobAttachments($id: ID!) {
    getJobById(id: $id) {
      id
      attachments {
        id
        contentType
        name
        signedUrl
        isArchived
        type
        status
        statusMessage
        previews(sizes: [SMALL, MEDIUM]) {
          id
          signedUrl
          previewSize
        }
      }
    }
  }
`

const ARCHIVE_ATTACHMENT = gql`
  mutation ArchiveAttachment($id: ID!) {
    archiveAttachment(id: $id)
  }
`

function isArchivable(file: Attachment): boolean {
  return file.type === AttachmentType.JOB
}

interface FileUploadProgress {
  [fileName: string]: number // Key is the file name, value is the progress percentage (0-100)
}

interface Props {
  readonly job: Job
  readonly onFileUpload?: () => void
}

function JobFileTab({ job, onFileUpload }: Props) {
  const { t } = useTranslation()
  const { hasPermissions } = useAuth()
  const [carouselFile, setCarouselFile] = useState<Attachment | null>()
  const [uploading, setUploading] = useState<boolean>(false)
  const [uploadProgress, setUploadProgress] = useState<FileUploadProgress>({})
  const [deleteTarget, setDeleteTarget] = useState<Attachment | null>()
  const [snack, setSnack] = useState<Snack>()

  const { data, loading, refetch } = useQuery(GET_JOB_ATTACHMENTS, {
    variables: { id: job.id },
    fetchPolicy: "cache-and-network",
  })

  const [archiveAttachment] = useMutation(ARCHIVE_ATTACHMENT, {
    onCompleted: () => {
      refetch()
      setDeleteTarget(null)
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  const jobAttachments = data?.getJobById?.attachments?.filter((a: Attachment) => !a.isArchived)

  const openFile = (attachment: Attachment) => {
    setCarouselFile(attachment)
  }

  const closeCarousel = () => {
    setCarouselFile(null)
  }

  const uploadFile = async (file) => {
    const data = new FormData()
    data.append("file", file)
    setUploadProgress((prevProgress) => ({
      ...prevProgress,
      [file.name]: 0,
    }))

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.open("POST", `${process.env.GATSBY_API_SERVER_URL}/attachment/job/${job.id}/attachments`)
      xhr.setRequestHeader("Authorization", `Bearer ${Cookies.get("authToken")}`)

      // Track upload progress
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100
          setUploadProgress((prevProgress) => ({
            ...prevProgress,
            [file.name]: percentComplete,
          }))
        }
      }

      // Handle load and error cases
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.response)
        } else {
          // Reject the promise with the status text and status code if there's an error
          reject({ status: xhr.status, statusText: xhr.statusText })
        }
      }

      xhr.onerror = () => {
        // Handle network errors
        reject({ status: xhr.status, statusText: xhr.statusText })
      }

      xhr.send(data)
    })
  }

  const uploadFiles = async (files: File[]) => {
    if (files.length > 0) {
      setUploading(true)

      const promises = files.map((file: File) => uploadFile(file))

      return await Promise.all(promises).then(
        () => {
          setUploading(false)
          setUploadProgress({})
          onFileUpload?.()
        },
        () => {
          setUploading(false)
          setUploadProgress({})
          setSnack({ messageKey: "component.jobFileTab.uploadFailed", variant: "error" })
        }
      )
    } else {
      return []
    }
  }

  return (
    <Box
      sx={{
        margin: "0 auto",
        maxWidth: 960,
        paddingBottom: "2rem",
      }}
    >
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      {loading ? (
        <div>
          <CircularProgress />
        </div>
      ) : (
        <>
          {hasPermissions?.([DefaultPermission.UpdateJob]) ? (
            <FileSelector
              onFilesAdded={(newFiles) => {
                uploadFiles(newFiles)
              }}
              showFileList={false}
              showThumbs={false}
            />
          ) : null}
          <Box
            sx={{
              width: "100%",
              margin: "0.625rem auto 3rem auto",
              borderRadius: "4px",
              border: "1px solid #ccc",
              backgroundColor: "#ddd",
              height: "600px",
              overflowY: "scroll",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
              }}
            >
              {jobAttachments?.map((attachment: Attachment) => {
                if (attachment.status === AttachmentStatus.COMPLETED) {
                  const smallPreview = attachment.previews?.find(
                    (p) => p.previewSize === AttachmentPreviewSize.SMALL
                  )
                  const isImage = attachment.contentType?.startsWith("image")
                  return (
                    <Card
                      key={attachment.id}
                      onClick={() => {
                        if (isImage) {
                          openFile(attachment)
                        } else {
                          window.open(attachment.signedUrl, "_blank", "width=1200,height=900")
                        }
                      }}
                      sx={classes.card}
                    >
                      {smallPreview?.signedUrl ? (
                        <img
                          alt={attachment.name}
                          height={150}
                          loading="lazy"
                          src={smallPreview.signedUrl}
                          style={{
                            ...classes.cardMedia,
                            display: "block",
                            objectPosition: isImage ? "center center" : "top center",
                            objectFit: "cover",
                            backgroundColor: "#212121",
                          }}
                          title={attachment.name}
                        />
                      ) : (
                        <CardMedia sx={classes.cardMedia}>
                          <FileTypeIcon
                            fileName={attachment.name}
                            style={{
                              color: "black",
                              width: "100%",
                              height: "100%",
                              padding: "1.875rem",
                              background: "#FFC601",
                            }}
                          />
                        </CardMedia>
                      )}
                      <CardContent sx={classes.cardContent}>
                        <Box
                          sx={{
                            fontSize: "0.75rem",
                            fontWeight: "500",
                            color: (theme: Theme) => theme.fielderColors.black,
                            wordBreak: "break-word",
                          }}
                        >
                          {getFilename(attachment, t)}
                        </Box>
                        {isArchivable(attachment) &&
                        hasPermissions?.([DefaultPermission.UpdateJob]) ? (
                          <Box
                            sx={{
                              marginLeft: "1.25rem",
                            }}
                          >
                            <IconButton
                              aria-label="delete file"
                              onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                setDeleteTarget(attachment)
                              }}
                            >
                              <DeleteIcon style={{ fontSize: "1rem" }} />
                            </IconButton>
                          </Box>
                        ) : null}
                      </CardContent>
                    </Card>
                  )
                } else if (attachment.status === AttachmentStatus.IN_PROGRESS) {
                  return (
                    <Card key={attachment.id} sx={classes.card}>
                      <CardMedia
                        sx={{
                          ...classes.cardMedia,
                          color: "black",
                          width: "100%",
                          height: "100%",
                          padding: "1.875rem",
                          background: (theme) => theme.fielderColors.brandPrimaryFaded,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "0.5rem",
                        }}
                      >
                        <ConstructionIcon style={{ fontSize: "3rem", color: "#000" }} />
                        <Box sx={{ fontSize: "0.675rem", textAlign: "center" }}>
                          {t("component.jobFileTab.fileInProgress")}
                        </Box>
                      </CardMedia>
                      <CardContent sx={classes.cardContent}>
                        <Box
                          sx={{
                            fontSize: "0.75rem",
                            fontWeight: "500",
                            color: (theme: Theme) => theme.fielderColors.black,
                            wordBreak: "break-word",
                          }}
                        >
                          {getFilename(attachment, t)}
                        </Box>
                      </CardContent>
                    </Card>
                  )
                } else if (attachment.status === AttachmentStatus.ERROR) {
                  return (
                    <Card key={attachment.id} sx={classes.card}>
                      <CardMedia
                        sx={{
                          ...classes.cardMedia,
                          color: "black",
                          width: "100%",
                          height: "100%",
                          padding: "1.875rem",
                          background: (theme) => theme.fielderColors.brandPrimaryFaded,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "0.5rem",
                        }}
                      >
                        <WarningIcon style={{ fontSize: "3rem", color: "#000" }} />
                        <Box sx={{ fontSize: "0.675rem", textAlign: "center" }}>
                          {t(`component.jobFileTab.fileError.${attachment.statusMessage}`)}
                        </Box>
                      </CardMedia>
                      <CardContent sx={classes.cardContent}>
                        <Box
                          sx={{
                            fontSize: "0.75rem",
                            fontWeight: "500",
                            color: (theme: Theme) => theme.fielderColors.black,
                            wordBreak: "break-word",
                          }}
                        >
                          {getFilename(attachment, t)}
                        </Box>
                        {isArchivable(attachment) &&
                        hasPermissions?.([DefaultPermission.UpdateJob]) ? (
                          <Box
                            sx={{
                              marginLeft: "1.25rem",
                            }}
                          >
                            <IconButton
                              aria-label="delete file"
                              onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                setDeleteTarget(attachment)
                              }}
                            >
                              <DeleteIcon style={{ fontSize: "1rem" }} />
                            </IconButton>
                          </Box>
                        ) : null}
                      </CardContent>
                    </Card>
                  )
                }
              })}
              {uploading
                ? Object.keys(uploadProgress).map((fileName) => {
                    return (
                      <Card key={fileName} sx={classes.card}>
                        <CardMedia
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                            height: "9.375rem",
                          }}
                        >
                          <Box sx={{ width: "100%", padding: "0.625rem" }}>
                            <LinearProgress
                              value={uploadProgress[fileName]}
                              variant="determinate"
                            />
                            <Box sx={{ fontSize: "0.875rem" }}>
                              {Math.round(uploadProgress[fileName])}%
                            </Box>
                          </Box>
                        </CardMedia>
                        <CardContent sx={[classes.cardContent, { fontSize: "0.75rem" }]}>
                          {fileName}
                        </CardContent>
                      </Card>
                    )
                  })
                : null}
            </Box>
          </Box>
        </>
      )}
      {carouselFile ? (
        <Modal onClose={closeCarousel} open>
          <Box
            sx={{
              position: "absolute",
              width: "1200px",
              height: "1000px",
              maxWidth: "90vw",
              maxHeight: "80vh",
              backgroundColor: "#212121",
              borderRadius: 1,
              boxShadow: (theme: Theme) => theme.shadows[5],
              padding: "0.5rem",
              top: `50%`,
              left: `50%`,
              transform: `translate(-50%, -50%)`,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  flexShrink: 1,
                  maxHeight: "calc(100% - 36px)",
                  width: "100%",
                  alignSelf: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={
                    carouselFile.previews?.find(
                      (p) => p.previewSize === AttachmentPreviewSize.MEDIUM
                    )?.signedUrl ?? carouselFile.signedUrl
                  }
                  style={{
                    width: "100%",
                    height: "auto",
                    maxHeight: "calc(100% - 0.5rem)",
                    objectFit: "contain",
                    backgroundColor: "#212121",
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  justifySelf: "flex-end",
                }}
              >
                <Button
                  color="primary"
                  onClick={() => window.open(carouselFile.signedUrl, "_blank")}
                  variant="contained"
                >
                  {t("openInNewTab")}
                </Button>
                <Button onClick={closeCarousel} variant="contained">
                  {t("close")}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      ) : null}
      {deleteTarget ? (
        <Dialog
          aria-describedby="archive-job-file-dialog-description"
          aria-labelledby="archive-job-file-dialog-title"
          onClose={() => setDeleteTarget(null)}
          open
        >
          <DialogTitle id="archive-file-dialog-title">{t("areYouSure")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="archive-file-dialog-description">
              {t("component.archiveJobFileDialog.confirmationPrompt", {
                name: deleteTarget.name,
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => setDeleteTarget(null)}>
              {t("no")}
            </Button>
            <Button
              autoFocus
              color="primary"
              onClick={() => archiveAttachment({ variables: { id: deleteTarget.id } })}
            >
              {t("yes")}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </Box>
  )
}

const classes = {
  card: {
    margin: "0.3125rem",
    border: "1px solid #c9c9c9",
    display: "flex",
    flexDirection: "column",
  },
  cardContent: {
    paddingLeft: "0.75rem",
    paddingRight: "0.25rem",
    paddingTop: "0.25rem",
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 1,

    // override MUI styling
    "&:last-child": {
      paddingBottom: "0.25rem",
    },
  },
  cardMedia: {
    height: "9.375rem",
    cursor: "pointer",
  },
}

export default JobFileTab
