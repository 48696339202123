import { create } from "zustand"
import { persist, createJSONStorage } from "zustand/middleware"
import type { Dayjs } from "dayjs"

import type { User, JobStatus, JobWorkflow, OrganizationStatus, OrganizationItemList } from "~/types/apiTypes"
import { SortDirection, ItemType, StockType } from "~/types/apiTypes"
import { TimeFrameOption, type DispatchDailySort } from "~/types/appTypes"
import { createDayJS } from "~/util/dateUtils"

const STORAGE_KEY = "fielder-storage"

interface ListScreenSettings {
  rowsPerPage: number
  currentPage: number
  searchTerm: string
  sortBy: string
  sortDir: SortDirection
}

const ORGANIZATION_ITEM_STATUS_FILTER = {
  ALL: "ALL",
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
} as const

const QUICK_FILTER = {
  IN_STOCK: "IN_STOCK",
} as const

type ObjectValues<T> = T[keyof T]
export type OrganizationItemStatusFilter = ObjectValues<typeof ORGANIZATION_ITEM_STATUS_FILTER>
export type QuickFilter = ObjectValues<typeof QUICK_FILTER>
export type ItemTypesFilter = ObjectValues<typeof ItemType>
export type StockTypesFilter = ObjectValues<typeof StockType>

/**
 * Filters in place on the Inventory List screen
 */
export interface InventoryListFilters {
  status: OrganizationItemStatusFilter
  itemTypes: ItemTypesFilter[]
  stockTypes: StockTypesFilter[]
  quickFilters: QuickFilter[]
  selectedList?: OrganizationItemList
}

/**
 * Filters in place on the Franchisee List screen
 */
export interface FranchiseeListFilters {
  statuses: OrganizationStatus[]
  regionIds: string[]
  accountManagerIds: string[]
}

export interface OrganizationDetailScreenSettings {
  isDefaultEstimateSettingsExpanded: boolean
  isDefaultInvoiceSettingsExpanded: boolean
}

export type JobsScreenMode = "list" | "board"

interface JobsScreenSettings extends ListScreenSettings {
  mode: JobsScreenMode
}

interface AppState {
  user: Partial<User> | null
  tempUser: Partial<User> | null
  inventoryListInStockToggle: boolean
  inventoryListFilters: InventoryListFilters
  inventoryListSettings: ListScreenSettings
  catalogListSettings: ListScreenSettings
  customerListSettings: ListScreenSettings
  organizationDetailScreenSettings: OrganizationDetailScreenSettings
  jobsScreenSettings: JobsScreenSettings
  franchiseeOrdersListSettings: ListScreenSettings
  isDrawerOpen: boolean
  jobStatusSelectorValue: Partial<JobStatus> | null
  jobBoardWorkflow: JobWorkflow | null
  objectIdForEditing: string | null
  estimateRequestCount: number
  unreadNotificationCount: number
  onlyShowUnreadNotifications: boolean
  includeArchivedCustomersToggle: boolean
  includeArchivedUsersToggle: boolean
  includeArchivedItemsToggle: boolean
  showArchivedProductOrdersToggle: boolean
  onlyShowMyTasksToggle: boolean
  hideCompletedTasksToggle: boolean
  franchiseeListFilters: FranchiseeListFilters
  franchiseeListSettings: ListScreenSettings
  filterOrdersByAccountManagerId: string | null
  taskListSettings: ListScreenSettings
  dispatchSelectedDate: string | null
  dispatchSelectedTimeFrame: TimeFrameOption
  dispatchDailySorts: DispatchDailySort[]
  setUser: (user: Partial<User> | null) => void
  removeUser: () => void
  setObjectIdForEditing: (id: string) => void
  removeObjectIdForEditing: () => void
  setJobStatusSelectorValue: (status: Partial<JobStatus>) => void
  setJobBoardWorkflow: (workflow: Partial<JobWorkflow>) => void
  removeJobBoardWorkflow: () => void
  setOrganizationDetailScreenSettings: (settings: OrganizationDetailScreenSettings) => void
  setInventoryListFilters: (filters: InventoryListFilters) => void
  setInventoryListSettings: (settings: ListScreenSettings) => void
  setCatalogListSettings: (settings: ListScreenSettings) => void
  setCustomerListSettings: (settings: ListScreenSettings) => void
  setJobsScreenSettings: (settings: JobsScreenSettings) => void
  setFranchiseeOrdersListSettings: (settings: ListScreenSettings) => void
  setIncludeArchivedCustomersToggle: (isOn: boolean) => void
  setIncludeArchivedUsersToggle: (isOn: boolean) => void
  setIncludeArchivedItemsToggle: (isOn: boolean) => void
  setShowArchivedProductOrdersToggle: (isOn: boolean) => void
  setOnlyShowUnreadNotificationsToggle: (isOn: boolean) => void
  setOnlyShowMyTasksToggle: (isOn: boolean) => void
  setHideCompletedTasksToggle: (isOn: boolean) => void
  setInventoryListInStockToggle: (isOn: boolean) => void
  setTempUser: (user: Partial<User> | null) => void
  removeTempUser: () => void
  resetStore: () => void
  setUnreadNotificationCount: (count: number) => void
  setEstimateRequestCount: (count: number) => void
  setFranchiseeListFilters: (filters: FranchiseeListFilters) => void
  setFranchiseeListSettings: (settings: ListScreenSettings) => void
  setFilterOrdersByAccountManagerId: (id: string | null) => void
  setTaskListSettings: (settings: ListScreenSettings) => void
  setDispatchSelectedDate: (date: string | null) => void
  setDispatchSelectedTimeFrame: (timeFrame: TimeFrameOption) => void
  setDispatchDailySorts: (data: DispatchDailySort[]) => void
  findDispatchDailySortForDate: (date: string | Dayjs) => DispatchDailySort | undefined
}

const initialState = {
  user: null,
  tempUser: null,
  includeArchivedCustomersToggle: false,
  includeArchivedUsersToggle: false,
  includeArchivedItemsToggle: false,
  inventoryListFilters: {
    status: ORGANIZATION_ITEM_STATUS_FILTER.ACTIVE,
    itemTypes: [],
    stockTypes: [],
    quickFilters: [],
  },
  organizationDetailScreenSettings: {
    isDefaultEstimateSettingsExpanded: false,
    isDefaultInvoiceSettingsExpanded: false,
  },
  inventoryListSettings: {
    rowsPerPage: 10,
    currentPage: 0,
    searchTerm: "",
    sortBy: "name",
    sortDir: SortDirection.ASC,
  },
  catalogListSettings: {
    rowsPerPage: 10,
    currentPage: 0,
    searchTerm: "",
    sortBy: "name",
    sortDir: SortDirection.ASC,
  },
  customerListSettings: {
    rowsPerPage: 10,
    currentPage: 0,
    searchTerm: "",
    sortBy: "name",
    sortDir: SortDirection.ASC,
  },
  franchiseeOrdersListSettings: {
    rowsPerPage: 10,
    currentPage: 0,
    searchTerm: "",
    sortBy: "number",
    sortDir: SortDirection.ASC,
  },
  jobsScreenSettings: {
    rowsPerPage: 10,
    currentPage: 0,
    searchTerm: "",
    sortBy: "number",
    sortDir: SortDirection.ASC,
    mode: "board" as JobsScreenMode,
  },
  showArchivedProductOrdersToggle: false,
  onlyShowMyTasksToggle: false,
  hideCompletedTasksToggle: true,
  inventoryListInStockToggle: false,
  isDrawerOpen: true,
  jobStatusSelectorValue: null,
  jobBoardWorkflow: null,
  objectIdForEditing: null,
  estimateRequestCount: 0,
  unreadNotificationCount: 0,
  onlyShowUnreadNotifications: true,
  franchiseeListFilters: {
    statuses: [],
    regionIds: [],
    accountManagerIds: [],
  },
  franchiseeListSettings: {
    rowsPerPage: 50,
    currentPage: 0,
    searchTerm: "",
    sortBy: "tradeName",
    sortDir: SortDirection.ASC,
  },
  filterOrdersByAccountManagerId: null,
  taskListSettings: {
    rowsPerPage: 25,
    currentPage: 0,
    searchTerm: "",
    sortBy: "dueDate",
    sortDir: SortDirection.DESC,
  },
  dispatchSelectedDate: null,
  dispatchSelectedTimeFrame: TimeFrameOption.DAY,
  dispatchDailySorts: [],
}

const useStore = create<AppState>()(
  persist(
    (set, get) => {
      return {
        ...initialState,
        setUser: (user: Partial<User> | null) => {
          set({ user })
          if ("undefined" !== typeof user?.unreadNotificationCount) {
            set({ unreadNotificationCount: user.unreadNotificationCount })
          }
        },
        removeUser: () => {
          set({ user: null })
        },
        setObjectIdForEditing: (id: string) => {
          set({ objectIdForEditing: id })
        },
        removeObjectIdForEditing: () => {
          set({ objectIdForEditing: null })
        },
        setJobStatusSelectorValue: (status: Partial<JobStatus>) => {
          set({ jobStatusSelectorValue: status })
        },
        setJobBoardWorkflow: (workflow: Partial<JobWorkflow>) => {
          set({ jobBoardWorkflow: workflow })
        },
        removeJobBoardWorkflow: () => {
          set({ jobBoardWorkflow: null })
        },
        setIncludeArchivedCustomersToggle: (isOn: boolean) => {
          set({ includeArchivedCustomersToggle: isOn })
        },
        setIncludeArchivedUsersToggle: (isOn: boolean) => {
          set({ includeArchivedUsersToggle: isOn })
        },
        setShowArchivedProductOrdersToggle: (isOn: boolean) => {
          set({ showArchivedProductOrdersToggle: isOn })
        },
        setOnlyShowMyTasksToggle: (isOn: boolean) => {
          set({ onlyShowMyTasksToggle: isOn })
        },
        setHideCompletedTasksToggle: (isOn: boolean) => {
          set({ hideCompletedTasksToggle: isOn })
        },
        setOrganizationDetailScreenSettings: (settings: OrganizationDetailScreenSettings) => {
          set({ organizationDetailScreenSettings: settings })
        },
        setInventoryListFilters: (filters: InventoryListFilters) => {
          set({ inventoryListFilters: filters })
        },
        setIncludeArchivedItemsToggle: (isOn: boolean) => {
          set({ includeArchivedItemsToggle: isOn })
        },
        setInventoryListInStockToggle: (isOn: boolean) => {
          set({ inventoryListInStockToggle: isOn })
        },
        setOnlyShowUnreadNotificationsToggle: (isOn: boolean) => {
          set({ onlyShowUnreadNotifications: isOn })
        },
        setTempUser: (user: Partial<User> | null) => {
          set({ tempUser: user })
        },
        removeTempUser: () => {
          set({ tempUser: null })
        },
        setUnreadNotificationCount: (count: number) => {
          set({ unreadNotificationCount: count })
        },
        setEstimateRequestCount: (count: number) => {
          set({ estimateRequestCount: count })
        },
        resetStore: () => {
          set({ ...initialState })
        },
        setCatalogListSettings: (settings: ListScreenSettings) => {
          set({ catalogListSettings: settings })
        },
        setCustomerListSettings: (settings: ListScreenSettings) => {
          set({ customerListSettings: settings })
        },
        setFranchiseeOrdersListSettings: (settings: ListScreenSettings) => {
          set({ franchiseeOrdersListSettings: settings })
        },
        setInventoryListSettings: (settings: ListScreenSettings) => {
          set({ inventoryListSettings: settings })
        },
        setJobsScreenSettings(settings: JobsScreenSettings) {
          set({ jobsScreenSettings: settings })
        },
        setFranchiseeListFilters: (filters: FranchiseeListFilters) => {
          set({ franchiseeListFilters: filters })
        },
        setFranchiseeListSettings: (settings: ListScreenSettings) => {
          set({ franchiseeListSettings: settings })
        },
        setFilterOrdersByAccountManagerId: (id: string | null) => {
          set({ filterOrdersByAccountManagerId: id })
        },
        setTaskListSettings: (settings: ListScreenSettings) => {
          set({ taskListSettings: settings })
        },
        setDispatchSelectedDate(date) {
          set({ dispatchSelectedDate: date })
        },
        setDispatchSelectedTimeFrame(timeFrame) {
          set({ dispatchSelectedTimeFrame: timeFrame })
        },
        setDispatchDailySorts(sorts) {
          set({ dispatchDailySorts: sorts })
        },
        findDispatchDailySortForDate(date: string | Dayjs, timeZone: string) {
          const dateAsDayJS = createDayJS(date, timeZone)
          const result = get().dispatchDailySorts.filter((d) => {
            const cDate = createDayJS(d.date, timeZone)
            return cDate?.isSameOrBefore(dateAsDayJS, "day")
          }).slice(-1)[0]
          return result ?? get().dispatchDailySorts[0]
        }
      }
    },
    {
      name: STORAGE_KEY,
      version: 1,
      storage: createJSONStorage(() => window?.localStorage),
    }
  )
)

export default useStore

const userSelector = (state: AppState) => state.user
const setUserSelector = (state: AppState) => state.setUser
const objectIdForEditingSelector = (state: AppState) => state.objectIdForEditing
const setObjectIdForEditingSelector = (state: AppState) => state.setObjectIdForEditing
const jobStatusSelectorValueSelector = (state: AppState) => state.jobStatusSelectorValue
const setJobStatusSelectorValueSelector = (state: AppState) => state.setJobStatusSelectorValue
const jobBoardWorkflowSelector = (state: AppState) => state.jobBoardWorkflow
const setJobBoardWorkflowSelector = (state: AppState) => state.setJobBoardWorkflow
const includeArchivedCustomersToggleSelector = (state: AppState) =>
  state.includeArchivedCustomersToggle
const includeArchivedUsersToggleSelector = (state: AppState) =>
  state.includeArchivedUsersToggle
const includeArchivedItemsToggleSelector = (state: AppState) => state.includeArchivedItemsToggle
const showArchivedProductOrdersToggleSelector = (state: AppState) => state.showArchivedProductOrdersToggle
const inventoryListInStockToggleSelector = (state: AppState) => state.inventoryListInStockToggle
const setIncludeArchivedCustomersToggleSelector = (state: AppState) =>
  state.setIncludeArchivedCustomersToggle
const setIncludeArchivedUsersToggleSelector = (state: AppState) =>
  state.setIncludeArchivedUsersToggle
const setIncludeArchivedItemsToggleSelector = (state: AppState) =>
  state.setIncludeArchivedItemsToggle
const setShowArchivedProductOrdersToggleSelector = (state: AppState) => state.setShowArchivedProductOrdersToggle
const setInventoryListInStockToggleSelector = (state: AppState) =>
  state.setInventoryListInStockToggle
const tempUserSelector = (state: AppState) => state.tempUser
const setTempUserSelector = (state: AppState) => state.setTempUser
const removeTempUserSelector = (state: AppState) => state.removeTempUser
const unreadNotificationCountSelector = (state: AppState) => state.unreadNotificationCount
const setUnreadNotificationCountSelector = (state: AppState) => state.setUnreadNotificationCount
const estimateRequestCountSelector = (state: AppState) => state.estimateRequestCount
const setEstimateRequestCountSelector = (state: AppState) => state.setEstimateRequestCount
const resetStoreSelector = (state: AppState) => state.resetStore
const franchiseeListFiltersSelector = (state: AppState) => state.franchiseeListFilters
const setFranchiseeListFiltersSelector = (state: AppState) => state.setFranchiseeListFilters
const franchiseeListSettingsSelector = (state: AppState) => state.franchiseeListSettings
const setFranchiseeListSettingsSelector = (state: AppState) => state.setFranchiseeListSettings
const filterOrdersByAccountManagerIdSelector = (state: AppState) =>
  state.filterOrdersByAccountManagerId
const setFilterOrdersByAccountManagerIdSelector = (state: AppState) =>
  state.setFilterOrdersByAccountManagerId
const taskListSettingsSelector = (state: AppState) => state.taskListSettings
const setTaskListSettingsSelector = (state: AppState) => state.setTaskListSettings
const onlyShowMyTasksToggleSelector = (state: AppState) => state.onlyShowMyTasksToggle
const setOnlyShowMyTasksToggleSelector = (state: AppState) => state.setOnlyShowMyTasksToggle
const hideCompletedTasksToggleSelector = (state: AppState) => state.hideCompletedTasksToggle
const setHideCompletedTasksToggleSelector = (state: AppState) => state.setHideCompletedTasksToggle
const onlyShowUnreadNotificationsToggleSelector = (state: AppState) => state.onlyShowUnreadNotifications
const setOnlyShowUnreadNotificationsToggleSelector = (state: AppState) => state.setOnlyShowUnreadNotificationsToggle
const inventoryListFiltersSelector = (state: AppState) => state.inventoryListFilters
const setInventoryListFiltersSelector = (state: AppState) => state.setInventoryListFilters
const dispatchSelectedDateSelector = (state: AppState) => state.dispatchSelectedDate
const setDispatchSelectedDateSelector = (state: AppState) => state.setDispatchSelectedDate
const dispatchSelectedTimeFrameSelector = (state: AppState) => state.dispatchSelectedTimeFrame
const setDispatchSelectedTimeFrameSelector = (state: AppState) => state.setDispatchSelectedTimeFrame
const dispatchDailySortsSelector = (state: AppState) => state.dispatchDailySorts
const setDispatchDailySortsSelector = (state: AppState) => state.setDispatchDailySorts
const findDispatchDailySortForDateSelector = (state: AppState) => state.findDispatchDailySortForDate

export {
  STORAGE_KEY,
  userSelector,
  setUserSelector,
  objectIdForEditingSelector,
  setObjectIdForEditingSelector,
  jobStatusSelectorValueSelector,
  setJobStatusSelectorValueSelector,
  jobBoardWorkflowSelector,
  setJobBoardWorkflowSelector,
  includeArchivedCustomersToggleSelector,
  includeArchivedUsersToggleSelector,
  includeArchivedItemsToggleSelector,
  inventoryListInStockToggleSelector,
  setIncludeArchivedCustomersToggleSelector,
  setIncludeArchivedUsersToggleSelector,
  setIncludeArchivedItemsToggleSelector,
  setInventoryListInStockToggleSelector,
  tempUserSelector,
  setTempUserSelector,
  removeTempUserSelector,
  unreadNotificationCountSelector,
  setUnreadNotificationCountSelector,
  estimateRequestCountSelector,
  setEstimateRequestCountSelector,
  resetStoreSelector,
  franchiseeListFiltersSelector,
  setFranchiseeListFiltersSelector,
  franchiseeListSettingsSelector,
  setFranchiseeListSettingsSelector,
  filterOrdersByAccountManagerIdSelector,
  setFilterOrdersByAccountManagerIdSelector,
  taskListSettingsSelector,
  setTaskListSettingsSelector,
  onlyShowMyTasksToggleSelector,
  setOnlyShowMyTasksToggleSelector,
  hideCompletedTasksToggleSelector,
  setHideCompletedTasksToggleSelector,
  showArchivedProductOrdersToggleSelector,
  setShowArchivedProductOrdersToggleSelector,
  onlyShowUnreadNotificationsToggleSelector,
  setOnlyShowUnreadNotificationsToggleSelector,
  inventoryListFiltersSelector,
  setInventoryListFiltersSelector,
  dispatchSelectedDateSelector,
  setDispatchSelectedDateSelector,
  dispatchSelectedTimeFrameSelector,
  setDispatchSelectedTimeFrameSelector,
  dispatchDailySortsSelector,
  setDispatchDailySortsSelector,
  findDispatchDailySortForDateSelector
}
