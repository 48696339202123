import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import { useQuery } from "@apollo/client"

import { Seo, SnackbarMessage, MainLayout, PageHeader } from "~/components"
import { useAuth } from "~/context/AuthContext"
import { Snack } from "~/types"
import { INBOX } from "~/util"
import InboxPanel from "./components/InboxPanel"
import { ALL_INBOX_CONVERSATIONS } from "~/queries/allInboxConversations"

interface Props {
  readonly location?: {
    state?: {
      snack?: Snack
    }
  }
}

function InboxPage({ location }: Props) {
  const { t } = useTranslation()
  const { user, hasPermissions } = useAuth()
  const [selectedConversationId, setSelectedConversationId] = useState<string | null>(null)
  const [statusFilter, setStatusFilter] = useState<"open" | "closed">("open")
  const [sortBy, setSortBy] = useState<"newest" | "oldest">("newest")
  const [snack, setSnack] = useState<Snack | null>(() => location?.state?.snack ?? null)

  const { loading, data, error } = useQuery(ALL_INBOX_CONVERSATIONS, {
    variables: {},
    fetchPolicy: "cache-and-network",
  })

  const conversations = data?.allInboxConversations.edges?.map((e) => e.node) ?? []

  return (
    <>
      <Seo title={t(INBOX.titleKey)} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={INBOX}>
        <Box sx={{ margin: "1.25rem", marginTop: "0" }}>
          <PageHeader icon={INBOX.icon} leafTitleKey={INBOX.titleKey} />
          <InboxPanel
            conversations={conversations}
            isLoadingConversations={false}
            onChangeSort={setSortBy}
            onChangeStatusFilter={setStatusFilter}
            onSelectConversation={setSelectedConversationId}
            selectedConversationId={selectedConversationId}
            selectedSort={sortBy}
            selectedStatus={statusFilter}
          />
        </Box>
      </MainLayout>
    </>
  )
}

export default InboxPage
