import React from "react"
import Box from "@mui/material/Box"

import { InboxConversation } from "~/types/apiTypes"
import ConversationSummary from "./ConversationSummary"
import { Divider } from "@mui/material"
import ConversationListMenuBar from "./ConversationListMenuBar"

interface Props {
  readonly conversations: InboxConversation[]
  readonly selectedConversationId?: string | null
  readonly onChangeStatusFilter: (status: "open" | "closed") => void
  readonly onChangeSort: (sort: "newest" | "oldest") => void
  readonly onSelectConversation: (conversationId: string) => void
  readonly selectedStatus: "open" | "closed"
  readonly selectedSort: "newest" | "oldest"
}

export default function ConversationList({
  conversations,
  selectedConversationId,
  onChangeStatusFilter,
  onChangeSort,
  onSelectConversation,
  selectedStatus,
  selectedSort,
}: Props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "25rem",
        maxWidth: "500px",
      }}
    >
      <ConversationListMenuBar
        onChangeSort={onChangeSort}
        onChangeStatusFilter={onChangeStatusFilter}
        selectedSort={selectedSort}
        selectedStatus={selectedStatus}
      />
      <Box
        sx={{
          height: "100%",
          maxHeight: "calc(100vh - 6rem)",
          overflowY: "scroll",
          paddingBottom: "4rem",
        }}
      >
        {conversations.map((conversation) => (
          <>
            <ConversationSummary
              conversation={conversation}
              isSelected={selectedConversationId === conversation.id}
              key={conversation.id}
              onClick={onSelectConversation}
            />
            <Divider />
          </>
        ))}
      </Box>
    </Box>
  )
}
