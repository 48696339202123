import gql from "graphql-tag"

export const ALL_INBOX_CONVERSATIONS = gql`
  query AllInboxConversations(
    $sortBy:String
    $sortDir:SortDirection
    $first:Int
    $after:String
    $last:Int
    $before:String
    $filter:String
    $status:InboxConversationStatus
  ) {
    allInboxConversations(
        input: {
            sortBy:$sortBy,
            sortDir:$sortDir,
            first:$first
            after:$after,
            last:$last,
            before:$before,
            filter:$filter
        }
        inboxConversationFilter: {
            status: $status
        }) {
          pageInfo {
            hasNextPage
          }
          edges {
            cursor
            node {
              id
              isRead
              status
              latestMessage {
                id
                subject
                sender {
                  name
                  messageAddress
                  user {
                    id
                    firstName
                    lastName
                    email
                  }
                }
                bodyHtml
                bodyPlain
                subject
                toRecipients {
                  name
                  messageAddress
                }
                ccRecipients {
                  name
                  messageAddress
                }
                channel
                direction
                dateSent
              }
              tags {
                id
                name
              }
              job {
                id
                number
                address {
                  addressString
                }
              }
            }
          }
    }
  }
`