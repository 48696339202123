/* eslint-disable react/jsx-no-literals */
import React, { useState } from "react"
import * as Sentry from "@sentry/react"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "@apollo/client"
import { Theme } from "@mui/material/styles"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { ErrorMessage, Seo } from "~/components"
import SnackbarMessage from "~/components/SnackbarMessage"
import MasterInfoRow from "~/components/MasterInfoRow"
import MasterInfoItem from "~/components/MasterInfoItem"
import TotalRow from "~/components/TotalRow"
import TotalRowContent from "~/components/TotalRowContent"
import invoiceLogo from "~/images/invoice_logo.png"
import { ACCEPT_ESTIMATE } from "~/queries/acceptEstimate"
import { GET_ESTIMATE_BY_ID } from "~/queries/getEstimateById"
import {
  formatAddress,
  formatPersonName,
  formatPhoneNumber,
  formatDiscount,
  formatMoney,
  formatTaxRate,
  parseGraphQLErrorCode,
  getJobDocumentDisplayNumber,
  formatDate,
} from "../util"
import orderBy from "lodash/orderBy"
import { GetEstimateByIdData, Snack } from "../types"
import PublicAppBar from "../components/PublicAppBar"

const formWidth = 900
const breakpoint1 = 768
const breakpoint2 = 924

const classes = {
  estimateMetaItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    "& span": {
      textAlign: "right",
      marginLeft: "0.625rem",
    },
  },
  organizationInfoItem: {
    width: "50%",
  },
} as const

function CustomerEstimate() {
  const { t } = useTranslation()
  const { id } = useParams()
  const [snack, setSnack] = useState<Snack>()

  const [acceptEstimate, { data: acceptEstimateData, loading: acceptEstimateLoading }] =
    useMutation(ACCEPT_ESTIMATE, {
      onCompleted: (data) => {
        if (data.acceptEstimate.accepted) {
          setSnack({ messageKey: "messages.estimateAccepted", variant: "success" })
        } else {
          setSnack({ messageKey: "error.general.message", variant: "error" })
        }
      },
      onError: (error) => {
        Sentry.captureException(error)
        const errorCode = parseGraphQLErrorCode(error)
        setSnack({ messageKey: errorCode, variant: "error" })
      },
      refetchQueries: () => {
        return ["GetEstimateById"]
      },
    })

  const { loading, data, error } = useQuery<GetEstimateByIdData>(GET_ESTIMATE_BY_ID, {
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
  })

  const handleAcceptEstimate = () => {
    if (estimate?.id) {
      const variables = {
        id: estimate.id,
      }
      acceptEstimate({ variables })
    } else {
      console.error(
        "Error - cannot accept Estimate - either the estimate was not found or it has no ID!"
      )
    }
  }

  const estimate = data?.getEstimateById
  const currencyCode = estimate?.currencyCode

  // Get the sorted tax amounts. Can't use Array.sort here because it
  // mutates the original array, which Apollo Client will complain about.
  const taxRateAmounts = estimate?.taxSummary?.taxRateAmounts
    ? orderBy(estimate.taxSummary?.taxRateAmounts, ["amount"], ["desc"])
    : []

  return (
    <>
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <Seo lang="en" title={t("estimate")} />
      <PublicAppBar />
      <Box
        component="main"
        sx={(theme: Theme) => ({
          width: "auto",
          fontSize: "0.375rem",
          mx: "1rem",
          [theme.breakpoints.up(breakpoint1)]: {
            fontSize: "0.75rem",
          },
          [theme.breakpoints.up(breakpoint2)]: {
            width: formWidth,
            fontSize: "0.875rem",
            marginLeft: "auto",
            marginRight: "auto",
          },
          "@media print": {
            margin: 0,
          },
        })}
      >
        {!loading && error ? (
          <Box
            sx={{
              textAlign: "center",
              marginTop: "10%",
              fontWeight: 600,
              fontSize: "1.2rem",
              color: (theme) => theme.palette.error.main,
            }}
          >
            <ErrorMessage
              data-testid="error-message"
              message={t("error.estimateNotFound.message")}
              title={t("error.estimateNotFound.title")}
            />
          </Box>
        ) : null}

        {!error && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                my: "1.5rem",
                "@media print": {
                  display: "none",
                },
              }}
            >
              <Button
                data-testid="printBtn"
                onClick={() => window.print()}
                sx={(theme) => ({
                  display: "none",
                  [theme.breakpoints.up(breakpoint1)]: {
                    display: "inline-flex",
                  },
                })}
                variant="outlined"
              >
                {t("print")}
              </Button>
              <Box
                sx={(theme) => ({
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  [theme.breakpoints.up(breakpoint1)]: {
                    width: "300px",
                  },
                  [theme.breakpoints.up(breakpoint2)]: {},
                })}
              >
                {!acceptEstimateData?.acceptEstimate?.accepted &&
                  estimate?.status !== "ACCEPTED" && (
                    <Button
                      color="primary"
                      data-testid="acceptEstimateBtn"
                      disabled={acceptEstimateLoading}
                      onClick={handleAcceptEstimate}
                      sx={{
                        marginLeft: "1.25rem",
                      }}
                      variant="contained"
                    >
                      <span style={{ visibility: acceptEstimateLoading ? "hidden" : "visible" }}>
                        {t("acceptEstimate")}
                        <CircularProgress
                          color="secondary"
                          size={20}
                          style={{
                            visibility: acceptEstimateLoading ? "visible" : "hidden",
                            position: "absolute",
                            left: "42%",
                          }}
                          thickness={6.0}
                        />
                      </span>
                    </Button>
                  )}
                {/* <Button data-testid="askQuestionBtn" variant="outlined">
                  {t("askQuestion")}
                </Button> */}
              </Box>
            </Box>
            <Paper
              elevation={3}
              sx={(theme: Theme) => ({
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                backgroundColor: "#ffffff",
                color: theme.fielderScreens.estimateRequest.color,
                mt: 0,
                mb: "1.5rem",
                p: "1.5rem",
                [theme.breakpoints.up(breakpoint1)]: {
                  mb: "3rem",
                  p: "1.5rem",
                  minHeight: "800px",
                },
                "& label": {
                  color: "#212121",
                },
                "@media print": {
                  paddingBottom: "0.5rem",
                  border: "none",
                  boxShadow: "none",
                  fontSize: "0.75rem",
                  minHeight: "100px",
                },
              })}
            >
              {loading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "25%",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : null}
              {!loading && estimate ? (
                <>
                  <Box>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          flexGrow: 1,
                          flexShrink: 1,
                          maxWidth: "50%",
                          "& img": {
                            maxWidth: "100%",
                          },
                        }}
                      >
                        <img alt="logo" src={invoiceLogo} />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          fontSize: "0.95rem",
                          flexGrow: 1,
                          flexShrink: 1,
                          marginLeft: "3.125rem",
                          maxWidth: "25%",
                          minWidth: "65px",
                        }}
                      >
                        <Box sx={classes.estimateMetaItem}>
                          <label>{t("estimate")} #</label>
                          <span>{getJobDocumentDisplayNumber(estimate)}</span>
                        </Box>
                        <Box css={classes.estimateMetaItem}>
                          <label>{t("dateCreated")}</label>
                          <span>
                            {formatDate(estimate.createdAt, t("format:dateFormat.short"))}
                          </span>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={(theme) => ({
                        px: "0.3125rem",
                        [theme.breakpoints.up(breakpoint1)]: {
                          px: "0.9375rem",
                        },
                      })}
                    >
                      <Box
                        sx={(theme) => ({
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          fontSize: "1.0em",
                          my: "0.625rem",
                          [theme.breakpoints.up(breakpoint1)]: {
                            my: "0.9375rem",
                          },
                          [theme.breakpoints.up(breakpoint2)]: {
                            my: "1.25rem",
                          },
                        })}
                      >
                        <Box sx={classes.organizationInfoItem}>
                          <Box>{estimate?.job?.organization?.tradeName}</Box>
                          {estimate?.job?.organization?.address ? (
                            <Box>
                              {formatAddress(estimate.job.organization.address.addressString)}
                            </Box>
                          ) : null}
                        </Box>
                        <Box sx={classes.organizationInfoItem}>
                          {estimate?.job?.organization?.phoneNumber ? (
                            <Box>
                              {t("phone")}:{" "}
                              {formatPhoneNumber(estimate.job.organization.phoneNumber)}
                            </Box>
                          ) : null}
                          {estimate?.job?.organization?.email ? (
                            <Box>
                              {t("email")}: {estimate.job.organization.email}
                            </Box>
                          ) : null}
                          {estimate?.job?.organization?.websiteURL ? (
                            <Box>
                              {t("web")}: {estimate.job.organization.websiteURL}
                            </Box>
                          ) : null}
                        </Box>
                      </Box>
                      <Box
                        sx={(theme) => ({
                          my: "0.625rem",
                          paddingTop: "1.25rem",
                          borderTop: "1px solid rgba(0,0,0,0.12)",
                          [theme.breakpoints.up(breakpoint1)]: {
                            my: "0.9375rem",
                          },
                          [theme.breakpoints.up(breakpoint2)]: {
                            my: "1.25rem",
                          },
                        })}
                      >
                        <MasterInfoRow>
                          <MasterInfoItem>
                            <label>{t("billTo")}</label>
                            {estimate.billingContact ? (
                              <>
                                <Box>{formatPersonName(estimate.billingContact)}</Box>
                                <Box>
                                  {estimate.billingContact.address
                                    ? formatAddress(estimate.billingContact.address.addressString)
                                    : null}
                                </Box>
                              </>
                            ) : null}
                          </MasterInfoItem>
                          <MasterInfoItem>
                            <label>{t("jobAddress")}</label>
                            {estimate?.job?.address ? (
                              <Box>{formatAddress(estimate.job.address.addressString)}</Box>
                            ) : null}
                          </MasterInfoItem>
                        </MasterInfoRow>
                        <MasterInfoRow>
                          <MasterInfoItem style={{ width: "100%" }}>
                            <label>{t("description")}</label>
                            <Box>{estimate.description}</Box>
                          </MasterInfoItem>
                        </MasterInfoRow>
                      </Box>
                      <Table
                        size="small"
                        sx={(theme) => ({
                          marginTop: "1.875rem",
                          "& th": {
                            fontWeight: "bold",
                            fontSize: "0.9rem !important",
                            lineHeight: "1.0rem !important",
                            paddingLeft: 0,
                            py: "0.3125rem",
                            paddingBottom: "0.3125rem",
                            [theme.breakpoints.up(breakpoint1)]: {
                              py: "0.625rem",
                            },
                            [theme.breakpoints.up(breakpoint2)]: {
                              paddingTop: "0.9375rem",
                            },
                            "&:last-child": {
                              paddingRight: 0,
                            },
                          },
                          "& td": {
                            fontSize: "0.84rem !important",
                            color: theme.fielderScreens.estimateRequest.color,
                            paddingLeft: 0,
                            "&:last-child": {
                              paddingRight: 0,
                            },
                          },
                        })}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell width="65%">{t("item")}</TableCell>
                            <TableCell align="right" width="10%">
                              {t("quantity")}
                            </TableCell>
                            <TableCell align="right" width="15%">
                              {t("unitPrice")}
                            </TableCell>
                            <TableCell align="right" width="10%">
                              {t("amount")}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {estimate?.lineItems?.map((li) => (
                            <>
                              <TableRow key={li.id}>
                                <TableCell>
                                  <strong>{li.organizationItem?.name}</strong>
                                  <Box>{li.organizationItem?.code}</Box>
                                </TableCell>
                                <TableCell align="right">{li.quantity}</TableCell>
                                <TableCell align="right">
                                  {li.unitPrice ? formatMoney(currencyCode, li.unitPrice) : null}
                                </TableCell>
                                <TableCell align="right">
                                  {li.total ? formatMoney(currencyCode, li.total) : null}
                                </TableCell>
                              </TableRow>
                              {li.showDetails
                                ? li.lineItemDetails
                                    ?.toSorted((a, b) => a.number - b.number)
                                    ?.map((d) => (
                                      <TableRow
                                        key={d.organizationItem?.id}
                                        sx={{
                                          fontSize: "0.84rem",
                                          backgroundColor: "#fafafa",
                                        }}
                                      >
                                        <TableCell>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              flexDirection: "column",
                                              marginLeft: "1.25rem",
                                            }}
                                          >
                                            <Box>
                                              <strong>{d.organizationItem?.name}</strong>
                                            </Box>
                                            <Box>{d.organizationItem?.code}</Box>
                                          </Box>
                                        </TableCell>
                                        <TableCell align="right">{d.quantity}</TableCell>
                                        <TableCell align="right">
                                          {d.unitPrice
                                            ? formatMoney(currencyCode, d.unitPrice)
                                            : null}
                                        </TableCell>
                                        <TableCell align="right" />
                                        <TableCell colSpan={1} />
                                      </TableRow>
                                    ))
                                : null}
                            </>
                          ))}
                        </TableBody>
                      </Table>
                      <Box
                        sx={(theme: Theme) => ({
                          marginTop: "0.625rem",
                          display: "flex",
                          justifyContent: "flex-end",
                          flexDirection: "column",
                          alignItems: "flex-end",
                          fontSize: "0.875rem",
                          [theme.breakpoints.up(breakpoint1)]: {
                            marginTop: "0.625rem",
                          },
                          [theme.breakpoints.up(breakpoint2)]: {
                            marginTop: "1.25rem",
                          },
                        })}
                      >
                        <TotalRow>
                          <TotalRowContent>
                            <label>{t("subTotal")}</label>
                            <span>
                              {estimate.subTotal
                                ? formatMoney(currencyCode, estimate.subTotal)
                                : null}
                            </span>
                          </TotalRowContent>
                        </TotalRow>
                        {estimate.discount?.value && estimate.discount.value > 0 ? (
                          <TotalRow>
                            <TotalRowContent>
                              <label>{t("discount")}</label>
                              <span>{formatDiscount(estimate)}</span>
                            </TotalRowContent>
                          </TotalRow>
                        ) : null}
                        {taxRateAmounts?.length > 0 && (
                          <Box sx={{ fontSize: "0.75rem", my: "0.25rem" }}>
                            <label css={{ fontSize: "0.875rem" }}>{t("tax")}</label>
                            {taxRateAmounts?.map((taxRateAmount) => (
                              <TotalRow key={taxRateAmount.taxRate.id}>
                                <TotalRowContent>
                                  <Box>
                                    <Box>{taxRateAmount.taxRate.name}</Box>
                                    <Box sx={{ fontSize: "0.6875rem", color: "#747474" }}>
                                      <em>{formatTaxRate(taxRateAmount.taxRate.rate)}</em>
                                    </Box>
                                  </Box>
                                  <Box data-testid="estimateDialogField_totalTax">
                                    {taxRateAmount.amount
                                      ? formatMoney(currencyCode, taxRateAmount.amount)
                                      : null}
                                  </Box>
                                </TotalRowContent>
                              </TotalRow>
                            ))}
                          </Box>
                        )}
                        <TotalRow>
                          <TotalRowContent>
                            <label>{t("totalTax")}</label>
                            <span>
                              {estimate?.taxSummary?.total
                                ? formatMoney(currencyCode, estimate.taxSummary.total)
                                : null}
                            </span>
                          </TotalRowContent>
                        </TotalRow>
                        <TotalRow
                          style={{
                            marginTop: "0.625rem",
                            borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                            paddingTop: "0.625rem",
                          }}
                        >
                          <TotalRowContent style={{ fontWeight: "bold" }}>
                            <label>{t("total")}</label>
                            <span>
                              {estimate?.total ? formatMoney(currencyCode, estimate.total) : null}
                            </span>
                          </TotalRowContent>
                        </TotalRow>
                      </Box>
                    </Box>
                  </Box>
                  {/* <Box className={classes.disclaimer}>{t("page.estimate.disclaimer")}</Box> */}
                </>
              ) : null}
            </Paper>
          </>
        )}
      </Box>
    </>
  )
}

export default CustomerEstimate
